// DEPENDENCIES
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsArrowRightCircle, BsEye } from 'react-icons/bs';
import { FiCreditCard } from 'react-icons/fi';
// COMPONENTS & STYLES
import { DriverTagsOrPenalties } from '../../../uiComponents/customComponents/DriverTagsOrPenalties';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { driverListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { agreementTypes } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  TableTagCell,
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
// API CALLS & MODELS
import { exportDriversWithAgreements } from '../../../api/get/driver.get';
import { getAllCities } from '../../../api/get/city.get';
import { Driver } from '../../../models/driver';
// HOOKS & UTILS & COMMONS
import { tagOptions } from '../../../consts/driver';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { SECONDARY_PURPLE_90 } from '../../../common/styles/Colors';
import { DRIVERS } from '../../../consts/routes';
import { City } from '../../../models/city';
import { APP_CONTEXT } from '../../../utils/context';
import { OptionList } from '../../../utils/props';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { useGetAndMergeDriversQuery } from '../../../api/driver/mergedDriversApiSlice';

export const DriverList = () => {
  const navigate = useNavigate();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [tagFilters, setTagFilters] = useState<OptionList[]>([]);
  const [selectedAgreementTypes, setSelectedAgreementTypes] = useState<OptionList[]>();
  const [cityOptions, setCityOptions] = useState<OptionList[]>([]);
  const [selectedCity, setSelectedCity] = useState<OptionList[]>();
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const [isSeeAllTagsModalOpen, setIsSeeAllTagsModalOpen] = useState<boolean>(false);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const {
    data: drivers,
    isLoading: driversIsLoading,
    isFetching: driversIsFetching,
  } = useGetAndMergeDriversQuery({ query: queryStringState, refetch: refetchData });

  const filters: FilterItem[] = [
    {
      name: 'agreement-type',
      element: (
        <DropDownFilter
          name="agreement_type"
          placeholder="Agreement type"
          options={agreementTypes}
          multiValues={selectedAgreementTypes}
          title="Agreement type"
          onChange={(items) => setSelectedAgreementTypes(items as OptionList[])}
        />
      ),
    },
    {
      name: 'tag',
      element: (
        <DropDownFilter
          name="tag"
          placeholder="Select a tag"
          options={tagOptions}
          multiValues={tagFilters}
          title="Tags"
          onChange={(items) => setTagFilters(items as OptionList[])}
        />
      ),
    },
    {
      name: 'city',
      element: (
        <DropDownFilter
          name="city"
          placeholder="Select a city"
          multiValues={selectedCity}
          options={cityOptions}
          title="City"
          onChange={(item) => setSelectedCity(item as OptionList[])}
        />
      ),
    },
    {
      name: 'date_created',
      element: (
        <DateRangeFilter
          title="Date created"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const onSeeAllTagsClick = (driver: Driver) => {
    setIsSeeAllTagsModalOpen(true);
    setSelectedDriver(driver);
  };

  const handleGetDriversResponse = useCallback(
    (count: number, drivers: Driver[]) => {
      const driverRows = drivers?.map((driver: Driver) => {
        return {
          rowData: { data: driver },
          cells: [
            <TableTextCell value={driver?.first_name ?? '-'} />,
            <TableTextCell value={driver?.last_name ?? '-'} />,
            <TableTextCell value={driver?.email ?? '-'} />,
            <TableTextCell value={driver?.agreement_type ?? '-'} />,
            <TableTextCell value={driver?.pco_no ?? '-'} />,
            <TableTextCell value={driver?.dvla_no ?? '-'} />,
            <TableTextCell value={driver?.address_city ?? '-'} />,
            // Show the latest tag only
            <FlexLayout styled={{ marginRight: 16 }} gap={16}>
              {driver?.tag_type?.length && driver?.tag_type?.length > 0 ? (
                <>
                  <TableTagCell tags={driver?.tag_type?.slice(-1)} />
                  <ActionIcon
                    onClick={() => onSeeAllTagsClick(driver)}
                    icon={<BsArrowRightCircle size={24} color={SECONDARY_PURPLE_90} />}
                    tooltip="See all tags"
                  />
                </>
              ) : null}
            </FlexLayout>,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => navigate(`${DRIVERS}/${driver?.id}`, { state: DRIVERS })}
                icon={<BsEye size={24} color={SECONDARY_PURPLE_90} />}
                tooltip="View driver page"
              />
              {driver?.ordway_update_payment_method_url && (
                <ActionIcon
                  icon={
                    <a href={driver?.ordway_update_payment_method_url} target="_blank">
                      <FiCreditCard size={24} color={SECONDARY_PURPLE_90} />
                    </a>
                  }
                  tooltip="Add payment link"
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(driverRows);
      setTotalRows(count);
    },
    [setTotalRows, navigate, setTableData]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('driverListPage');
    setPageTitle('Drivers');
    getAllCities().then((results) => {
      const items: OptionList[] = results.data.map((city: City) => {
        return { value: city.city_name, label: city.city_name.toUpperCase() };
      });
      setCityOptions(items);
    });
    setSortingColumn('updated_date');
    setSortAscending(false);
  }, [setActiveSideNav, setSortAscending, setPageTitle, setSortingColumn, defaultString]);

  useEffect(() => {
    setTableFilters([
      {
        columnName: 'driver.created_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
      { columnName: 'address_city', options: selectedCity ?? [] },
      { columnName: 'agreement_type', options: selectedAgreementTypes ?? [] },
      { columnName: 'tag_type', options: tagFilters },
    ]);
  }, [setTableFilters, dateRangeFilter, selectedAgreementTypes, selectedCity, tagFilters]);

  useEffect(() => {
    if (drivers) {
      handleGetDriversResponse(drivers.count, drivers.data);
    }
  }, [drivers, queryStringState, handleGetDriversResponse]);

  const onClearClick = useCallback(() => {
    setSelectedCity([]);
    setTagFilters([]);
    setDateRangeFilter([]);
    setSelectedAgreementTypes([]);
  }, [setDateRangeFilter]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={driversIsFetching || driversIsLoading}
        header="Driver list"
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        sortAscending={sortAscending}
        columns={driverListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={exportDriversWithAgreements}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={(driver: { data: Driver }) => navigate(`${DRIVERS}/${driver?.data?.id}`, { state: DRIVERS })}
        disableApply={invalidDates}
      />
      {selectedDriver && (
        <Modal
          styled={{ width: '80vw', minWidth: 400 }}
          title="Tags"
          open={isSeeAllTagsModalOpen}
          showClose
          onClose={() => {
            setIsSeeAllTagsModalOpen(false);
            setSelectedDriver(null);
          }}
        >
          <DriverTagsOrPenalties driver={selectedDriver} />
        </Modal>
      )}
    </>
  );
};
