import { useCallback, useEffect, useState } from 'react';
import { BsEye, BsPencil } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import moment from 'moment';
import { EditDocument } from './editDocument';
import { ActionIcon } from '../actionIcon/actionIcon';
import { Table } from '../table';
import { documentTableColumns } from '../tableColumns/tableColumns';
import { DEFAULT_NUM_ROWS_PER_PAGE, TableTextCell, getQueryString } from '../tableUtils/tableUtils';
import { SecondaryButton } from '../../buttons/secondaryButton/secondaryButton';
import { CollapsiblePanel } from '../../layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../modals/confirmationModal/confirmationModal';
import { Modal } from '../../modals/modal';
import { Text } from '../../text/text';
import { UploadDocument } from './uploadDocument';
import { docList } from '../../../consts/document';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { deleteDocument } from '../../../api/delete/document.delete';
import { getAllDocuments } from '../../../api/get/document.get';
import { Document } from '../../../models/document';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_70 } from '../../../common/styles/Colors';
import { MdFileDownload } from 'react-icons/md';
import { Notification } from '../../../uiComponents/toast/toast';

interface DocumentTableProps {
  categoryId: string;
  documentCategory: 'DRIVER' | 'AGREEMENT' | 'VEHICLE';
  tableHeader: string;
  embedded?: boolean;
}

const MIME_TYPES: Record<string, string> = {
  'image/png': '.png',
  'image/jpg': '.jpg',
  'image/jpeg': 'jpeg',
  'application/pdf': '.pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
};

export const handleDownload = (url: string, documentName: string) => {
  fetch(url)
    .then((response: Response) => response.blob())
    .then((blob: Blob) => {
      const extension: string = MIME_TYPES[blob?.type ?? 'image/jpg'] ?? '.jpg';
      const url: string = window.URL.createObjectURL(new Blob([blob]));
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.download = `${documentName}.${extension}` ?? 'downloaded-file';
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch(() => {
      Notification({
        type: 'error',
        title: 'Download error',
        message: 'There was a problem downloading this file.',
      });
    });
};

export const DocumentsTable = ({ categoryId, documentCategory, tableHeader, embedded }: DocumentTableProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState<boolean>(false);
  const [uploadLicenseSummaryModalOpen, setUploadLicenseSummaryModalOpen] = useState<boolean>(false);
  const [editDocumentModalOpen, setEditDocumentModalOpen] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [documentToEdit, setDocumentToEdit] = useState<Document>();
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetDocumentsResponse = useCallback(
    (count: number, documents: Document[]) => {
      const documentRows = documents?.map((document: Document) => {
        return {
          rowData: { data: document },
          cells: [
            <TableTextCell value={document?.document_type} color={SECONDARY_PURPLE_70} />,
            <TableTextCell value={moment(document?.created_date)?.format('DD MMM YYYY')} color={SECONDARY_PURPLE_70} />,
            <TableTextCell
              value={document?.expiry_date ? moment(document?.expiry_date)?.format('DD MMM YYYY') : '-'}
              color={SECONDARY_PURPLE_70}
            />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => window?.open(document?.s3_url)}
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View document"
              />
              {!document?.archived && document.document_type !== 'LICENSE-SUMMARY' && (
                <ActionIcon
                  onClick={() => {
                    setDocumentToEdit(document);
                    setEditDocumentModalOpen(true);
                  }}
                  icon={<BsPencil size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Edit document"
                />
              )}
              <ActionIcon
                icon={<MdFileDownload size={24} color={PRIMARY_PURPLE} />}
                tooltip="Download document"
                onClick={() =>
                  handleDownload(
                    document?.s3_url,
                    `${document?.document_category}_${document?.document_type}_${document?.id}`
                  )
                }
              />
              {!document?.archived && (
                <ActionIcon
                  onClick={() => {
                    setDocumentToEdit(document);
                    setConfirmationModalOpen(true);
                  }}
                  icon={<FiTrash2 size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Delete document"
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(documentRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchDocumentList = useCallback(
    (queryString: string) => {
      getAllDocuments(queryString).then((response: { count: number; data: Document[] }) => {
        handleGetDocumentsResponse(response.count, response.data);
      });
    },
    [handleGetDocumentsResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchDocumentList(queryString);
    },
    [fetchDocumentList, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('created_date');
    fetchDocumentList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=created_date:ASC&filter=category_id$eq=${categoryId}`);
  }, [fetchDocumentList, setSortingColumn, categoryId]);

  useEffect(() => {
    setTableFilters([{ columnName: 'category_id', options: { value: categoryId, label: categoryId } }]);
  }, [setTableFilters, categoryId]);

  return (
    <>
      <CollapsiblePanel
        header={
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
            {tableHeader}
          </Text>
        }
        expanded={!isCollapsed}
        onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
        styled={{ marginTop: 16 }}
      >
        <div>
          <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
            {tableHeader}
          </Text>
          <FlexLayout style={{ marginBottom: '16px', gap: '10px' }} itemsX="end">
            <SecondaryButton onClick={() => setUploadLicenseSummaryModalOpen(true)}>
              Upload license summary
            </SecondaryButton>
            <SecondaryButton onClick={() => setUploadDocumentModalOpen(true)}>Upload document</SecondaryButton>
          </FlexLayout>
          <Table
            variant="compact"
            tableTheme="purple"
            embedded={embedded}
            onColumnHeaderClick={(columnId: string) =>
              applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
            }
            sortAscending={sortAscending}
            columns={documentTableColumns}
            rows={tableData}
            totalRows={totalRows}
            rowsPerPage={numRowsPerPage}
            currentPageNumber={pageNumber}
            sortingColumn={sortingColumn}
            filters={[]}
            onSearchChange={(value: string) => {
              setSearchString(value);
              applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
            }}
            filterQuery={filterQuery}
            goToPage={(pageNumber: number) => {
              goToPageNumber(pageNumber);
              applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
            }}
            onNumRowsPerPageChange={(value: number) => {
              setNumRowsPerPage(value);
              goToPageNumber(0);
              applyFilters(0, value, searchString, sortingColumn, sortAscending);
            }}
            styled={{ marginTop: 24 }}
          />
        </div>
      </CollapsiblePanel>
      <Modal
        title="Upload document"
        showClose
        open={uploadDocumentModalOpen}
        onClose={() => setUploadDocumentModalOpen(false)}
        styled={{ width: '40vw', minWidth: '550px' }}
      >
        <UploadDocument
          documentTypes={docList[documentCategory] ?? []}
          categoryId={categoryId}
          documentCategory={documentCategory}
          onClose={(fetchData?: boolean) => {
            setUploadDocumentModalOpen(false);
            if (fetchData) {
              applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
            }
          }}
        />
      </Modal>
      <Modal
        title="Upload license summary"
        showClose
        open={uploadLicenseSummaryModalOpen}
        onClose={() => setUploadLicenseSummaryModalOpen(false)}
        styled={{ width: '40vw', minWidth: '550px' }}
      >
        <UploadDocument
          documentTypes={docList[documentCategory] ?? []}
          defaultDocumentType="LICENSE-SUMMARY"
          categoryId={categoryId}
          documentCategory={documentCategory}
          onClose={(fetchData?: boolean) => {
            setUploadLicenseSummaryModalOpen(false);
            if (fetchData) {
              applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
            }
          }}
        />
      </Modal>
      <Modal
        title="Edit document"
        showClose
        open={editDocumentModalOpen && documentToEdit != null}
        onClose={() => setEditDocumentModalOpen(false)}
        styled={{ width: '40vw', minWidth: '550px' }}
      >
        {documentToEdit ? (
          <EditDocument
            documentId={documentToEdit?.id}
            expiryDate={documentToEdit?.expiry_date}
            documentType={documentToEdit?.document_type}
            categoryId={documentToEdit?.category_id}
            onClose={(fetchData?: boolean) => {
              setEditDocumentModalOpen(false);
              setDocumentToEdit(undefined);
              if (fetchData) {
                applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
              }
            }}
          />
        ) : (
          <></>
        )}
      </Modal>
      <ConfirmationModal
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={() => {
          if (documentToEdit?.id) {
            deleteDocument(documentToEdit.id).then(() => {
              applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
              setConfirmationModalOpen(false);
            });
          }
        }}
        closeButtonCaption="No"
        confirmButtonCaption="Yes"
        title="Are you sure you want to delete this document?"
        isOpen={confirmationModalOpen}
      />
    </>
  );
};
