import { GenericAbortSignal } from 'axios';
import { Congestion } from '../../models/congestion';
import { Excess } from '../../models/excessMileage';
import api from '../../utils/api';

export const getAllCongestionCharges = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: Congestion[] }> =>
  api.get(`additional-charge/congestion${query && `?${query}`}`, { signal });

export const searchDriver = (driverName: string) => api.get(`driver/list/search?search=${driverName}`);

export const getAllExcessMileages = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: Excess[] }> =>
  api.get(`additional-charge/excess-mileage/all-charges${query && `?${query}`}`, { signal });
