import { VehicleSource } from '../models/vehicle';
import { AgreementType } from '../models/agreement';
import { ImageArrayType } from '../models/transfer';

type ChecklistField =
  | 'front'
  | 'back'
  | 'passengerSide'
  | 'driverSide'
  | 'chargeCables'
  | 'lockingNut'
  | 'repairKitTire'
  | 'dashboard'
  | 'spareWheelKit'
  | 'tools'
  | 'lockingWheelNut'
  | 'telematics';

export interface ChecklistItem {
  name: ChecklistField;
  label: string;
  s3Folder?: string;
  aditionalInfo?: string;
  required?: boolean;
}

export interface DamageResolutionItem {
  id?: string;
  date: string;
  supplier: string;
  resolution: string;
  photos?: Array<File | Blob>;
}

export interface DamageReportItem {
  id?: string;
  damaged_type: string;
  damaged_area: string;
  damage_description: string;
  damage_detail: string;
  files?: Array<File | Blob | ImageArrayType>;
  images_folder?: string;
  resolved?: boolean;
}

export interface ConditionReportParams {
  vrm?: string;
  reason?: string;
  application_id?: string;
  agreement_id?: string;
  agreement_status?: string;
  dynamic?: string;
  courtesy_id?: string;
  type?: string;
  agreement_type?: AgreementType;
  servicing_id?: string;
  ordway_customer_id?: string;
  vehicle_source?: VehicleSource;
  driver_id?: string;
  prevRoute: string;
}

export interface ConditionReportFields extends ConditionReportParams {
  front?: File | Blob;
  back?: File | Blob;
  passengerSide?: File | Blob;
  driverSide?: File | Blob;
  chargeCables?: File | Blob;
  lockingNut?: File | Blob;
  repairKitTire?: File | Blob;
  dashboard?: File | Blob;
  spareWheelKit?: boolean;
  tools?: boolean;
  lockingWheelNut?: boolean;
  telematics?: boolean;
  oilLevel?: string;
  screenWashLevel?: string;
  fuelLevel?: string;
  mileage?: string;
  tags?: string[];
  tags_note?: string;
  signature?: File | Blob;
  notes?: string;
  damage_logs?: Array<DamageReportItem>;
}

export const mandatoryPhotosItems: ChecklistItem[] = [
  {
    name: 'front',
    s3Folder: 'VEHICLE-FRONT',
    label: 'Vehicle Front',
    required: true,
  },
  {
    name: 'back',
    s3Folder: 'VEHICLE-BACK',
    label: 'Vehicle Back',
    required: true,
  },
  {
    name: 'passengerSide',
    s3Folder: 'VEHICLE-PASSENGER-SIDE',
    label: 'Vehicle Passenger Side',
    required: true,
  },
  {
    name: 'driverSide',
    s3Folder: 'VEHICLE-DRIVER-SIDE',
    label: 'Vehicle Driver Side',
    required: true,
  },
  {
    name: 'chargeCables',
    s3Folder: 'CHARGE-CABLES',
    label: 'Charge Cables',
    required: true,
  },
  {
    name: 'lockingNut',
    s3Folder: 'LOCKING-NUT',
    label: 'Locking Nut',
    required: true,
  },
  {
    name: 'repairKitTire',
    s3Folder: 'REPAIR-KIT-TIRE',
    label: 'Puncture repair kit / spare tire',
    required: true,
  },
  {
    name: 'dashboard',
    s3Folder: 'DASHBOARD',
    label: 'Dashboard',
    aditionalInfo: '(show mileage and warning lights)',
    required: true,
  },
];

export const vehicleInformationItems: ChecklistItem[] = [
  {
    name: 'spareWheelKit',
    label: 'Spare wheel / Tire inflation kit',
    required: true,
  },
  {
    name: 'tools',
    label: 'Tools',
    required: true,
  },
  {
    name: 'lockingWheelNut',
    label: 'Locking wheel nut',
    required: true,
  },
  {
    name: 'telematics',
    label: 'Telematics',
    aditionalInfo: '(Plugged in)',
    required: true,
  },
];

export const sliderLevels: string[] = ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];
