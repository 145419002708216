import { SideNav } from '../sideNav/sideNav';
import { PowerBi } from '../../powerBi/powerBi';

export const Dashboard = () => {
  return (
    <>
      <SideNav />
      <PowerBi
        accessToken="accessToken"
        reportId="reportId"
        embedUrl="https://app.powerbi.com/view?r=eyJrIjoiMmZiMDRiZDAtNjllYS00MTcxLTgwMTQtMWQxY2QwN2JmYzc5IiwidCI6IjFlOTBjMmE0LTRjMDYtNDczZS1hNDg4LWY0NjMxNDIyMDMwNSIsImMiOjh9"
      />
    </>
  );
};
