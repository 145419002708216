import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { Text } from '../../../uiComponents/text/text';
import { Slider } from '../../fleet/conditionReport/checkSections/sections/styles';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { SectionProps } from '../order/order';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { useEffect, useState } from 'react';
import { SectionHeader } from './sectionHeader';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { BURST_OPTIONS, isRental } from '../../../consts';
import { OrderSections, BurstWeeksButton, SliderBoundary, SliderLabels } from '../order/order.styles';
import { Modal } from '../../../uiComponents/modals/modal';
import { PlanSummary } from './evPackagesSection/planSummary';
import { EvPackagesContext, EvPackagesContextValue } from './evPackagesSection/evPackagesContext';
import { getUpcomingThursday } from '../../../uiComponents/customComponents/holidayTool/utils';
import moment from 'moment';

const getDateForFirstFullPayment = (weeksTillFirstPayment: number) => {
  const thursday: Date = getUpcomingThursday();
  return moment(thursday).add(weeksTillFirstPayment.toString(), 'weeks');
};

const MIN_TERM = 26;

export const R2BBurst = ({ isComplete, isLocked, progress, checkProgress }: SectionProps) => {
  const agreementLength: number = progress?.agreement_length_weeks ?? 0;
  const uberCleanDiscountAppliedWeeks: number = progress?.uber_clean_discount_applied_weeks ?? 0;
  const agreementWeeklyValue: number = +(progress?.agreement_value_weeks ?? '0');
  const [isPlanSumModal, setIsPlanSumModal] = useState<boolean>(false);
  const [totalDiscount, setTotalDiscount] = useState<number>(+(progress?.uber_clean_air_amount ?? '0') ?? 0);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [discountWeeksApplied, setDiscountWeeksApplied] = useState<number>(
    uberCleanDiscountAppliedWeeks === 0 ? agreementLength : uberCleanDiscountAppliedWeeks
  );
  const totalContractValueAfterDiscount: number = agreementLength * agreementWeeklyValue - totalDiscount;
  const weeklyDiscount: number = totalDiscount / discountWeeksApplied;
  const weeklyCharge: number = agreementWeeklyValue - weeklyDiscount < 0 ? 0 : agreementWeeklyValue - weeklyDiscount;
  const isDiscountGreaterThanTcv: boolean = totalDiscount > +(totalContractValueAfterDiscount ?? '0');
  const canSubmit: boolean = !isDiscountGreaterThanTcv && totalDiscount > 0;
  const predefinedSliderWeeks = BURST_OPTIONS?.filter(
    (option, index) => option?.weeksValue <= agreementLength && index > 0
  );
  predefinedSliderWeeks.pop();

  useEffect(() => {
    if (!isLocked && discountWeeksApplied === 0 && agreementLength > 0) {
      setDiscountWeeksApplied(agreementLength);
    }
  }, [agreementLength, isLocked, uberCleanDiscountAppliedWeeks, discountWeeksApplied]);

  const uberDiscountContextValue: EvPackagesContextValue = {
    vehicleDetails: {
      total_due: progress?.total_due,
      agreement_length_weeks: progress?.agreement_length_weeks,
      agreement_value_weeks: progress?.agreement_value_weeks,
      vat_value: progress?.vat_value,
      rental_value: progress?.rental_value,
      agreementType: progress?.application_type,
    },
    carAndChargeBoolean: false,
    uberCleanAirAmount: totalDiscount,
    applicationId: progress?.application_id,
    isCarAndChargeChecked: false,
    driverId: progress?.driver_id,
    isUberCleanChecked: true,
    shellCardNumber: '',
    applicationType: progress?.application_type,
    discountedWeeks: discountWeeksApplied,
    shellNumberError: null,
    isRental: isRental(progress?.application_type),
    canSubmit: canSubmit,
    onClose: () => setIsPlanSumModal(false),
    onConfirm: () => {
      checkProgress?.();
      setIsCollapsed(true);
      setIsPlanSumModal(false);
    },
  };

  return (
    <EvPackagesContext value={uberDiscountContextValue}>
      <CollapsiblePanel
        styled={{ marginTop: 16 }}
        expanded={!isCollapsed}
        locked={isLocked}
        onCollapse={() => {
          setIsCollapsed(!isCollapsed);
        }}
        header={
          <SectionHeader
            completedText="Completed"
            title="R2B Burst setup"
            isComplete={isComplete}
            isLocked={isLocked}
            expanded={false}
          />
        }
      >
        <div>
          <SectionHeader title="R2B Burst setup" isComplete={isComplete} isLocked={isLocked} expanded />
          <TextField
            type="number"
            label="Total clean air discount:"
            name="uber_clean_air_amount"
            placeholder="Total discount"
            error={
              isDiscountGreaterThanTcv
                ? { message: 'Discount cannot be greater than TCV.', type: 'onChange' }
                : undefined
            }
            defaultValue={progress?.uber_clean_air_amount ?? ''}
            styled={{ marginTop: 24, maxWidth: 200 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTotalDiscount(+e.target.value)}
          />
          <>
            <Text styled={{ marginTop: 24 }} color={PRIMARY_PURPLE} weight={500} variant="body6" block>
              Choose how many weeks the discount will be spread over:
            </Text>
            <FlexLayout gap={8} itemsY="center" styled={{ marginTop: 24 }}>
              <SliderBoundary onClick={() => setDiscountWeeksApplied(MIN_TERM)}>
                <Text variant="body6" color={PRIMARY_PURPLE} weight={300} block align="center">
                  {MIN_TERM}
                </Text>
                <Text variant="body8" color={PRIMARY_PURPLE} weight={300} block align="center">
                  weeks
                </Text>
              </SliderBoundary>
              <OrderSections>
                <Slider
                  value={discountWeeksApplied}
                  type="range"
                  min={MIN_TERM}
                  max={agreementLength}
                  $min={MIN_TERM}
                  $max={+(agreementLength ?? '0')}
                  $value={discountWeeksApplied}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDiscountWeeksApplied(+e.target.value);
                  }}
                />
                <SliderLabels>
                  {predefinedSliderWeeks?.map((option) => (
                    <BurstWeeksButton
                      key={option?.weeksValue}
                      $leftPosition={`${((option?.weeksValue - MIN_TERM) / (agreementLength - MIN_TERM)) * 100}%`}
                      onClick={() => setDiscountWeeksApplied(option?.weeksValue)}
                    >
                      <Text variant="body9" align="center" color={PRIMARY_PURPLE} weight={300} block>
                        {`${option?.weeks} / `}
                      </Text>
                      <Text variant="body9" align="center" color={PRIMARY_PURPLE} weight={300} block>
                        {option?.months}
                      </Text>
                    </BurstWeeksButton>
                  ))}
                </SliderLabels>
              </OrderSections>
              <SliderBoundary onClick={() => setDiscountWeeksApplied(agreementLength)}>
                <Text variant="body6" color={PRIMARY_PURPLE} weight={300} block align="center">
                  {agreementLength}
                </Text>
                <Text variant="body8" color={PRIMARY_PURPLE} weight={300} block align="center">
                  weeks
                </Text>
              </SliderBoundary>
            </FlexLayout>
            {totalDiscount > 0 && (
              <FlexLayout gap={0} itemsY="center" styled={{ marginTop: 48 }} wrap>
                <Text color={PRIMARY_PURPLE} variant="body7" weight={300} block styled={{ whiteSpace: 'nowrap' }}>
                  Driver will receive a weekly discount of &nbsp;
                </Text>
                <Text color={PRIMARY_PURPLE} weight={800}>
                  {` £${(agreementWeeklyValue - weeklyCharge)?.toFixed(2)}, `}&nbsp;
                </Text>
                <Text color={PRIMARY_PURPLE} variant="body7" weight={300} block styled={{ whiteSpace: 'nowrap' }}>
                  and pay &nbsp;
                </Text>
                <Text color={PRIMARY_PURPLE} weight={800}>
                  {` £${weeklyCharge?.toFixed(2)} `}&nbsp;
                </Text>
                {discountWeeksApplied < agreementLength ? (
                  <>
                    <Text color={PRIMARY_PURPLE} weight={300} styled={{ whiteSpace: 'nowrap' }}>
                      per week for the first&nbsp;
                    </Text>
                    <Text color={PRIMARY_PURPLE} weight={800} styled={{ whiteSpace: 'nowrap' }}>
                      {`${discountWeeksApplied}`}&nbsp;
                    </Text>
                    <Text color={PRIMARY_PURPLE} weight={300} styled={{ whiteSpace: 'nowrap' }}>
                      weeks. Thereafter, the driver will pay&nbsp;
                    </Text>
                    <Text color={PRIMARY_PURPLE} weight={800}>
                      {` £${agreementWeeklyValue?.toFixed(2)} `}&nbsp;
                    </Text>
                    <Text color={PRIMARY_PURPLE} weight={300} styled={{ whiteSpace: 'nowrap' }}>
                      per week for the remainder of the contract, starting on &nbsp;
                    </Text>
                    <Text color={PRIMARY_PURPLE} weight={800}>
                      {`${moment(getDateForFirstFullPayment(discountWeeksApplied))?.format('DD MMM YYYY')}.`}
                    </Text>
                  </>
                ) : (
                  <Text color={PRIMARY_PURPLE} weight={300} styled={{ whiteSpace: 'nowrap' }}>
                    per week.
                  </Text>
                )}
              </FlexLayout>
            )}
            <FlexLayout gap={16} itemsX="end" styled={{ marginTop: 48 }}>
              <PrimaryButton onClick={() => setIsPlanSumModal(true)}>Plan summary and confirmation</PrimaryButton>
            </FlexLayout>
          </>
          <Modal
            styled={{ width: '80%', minWidth: 600 }}
            title="Plan summary"
            open={isPlanSumModal}
            showClose
            onClose={() => setIsPlanSumModal(false)}
          >
            <PlanSummary />
          </Modal>
        </div>
      </CollapsiblePanel>
    </EvPackagesContext>
  );
};
