import React, { useCallback, useEffect, useState } from 'react';
import { PolicyVehiclesPayload, VehiclesPolicyPayload } from '../../../models/insurancePolicy';
import { Table } from '../../../uiComponents/table/table';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { policyVehiclesColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../common/styles/Colors';
import { TableTagCell, TableTextCell } from '../../../uiComponents/table/tableUtils/tableUtils';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { BsEye, BsFileEarmarkPlus, BsFileEarmarkX } from 'react-icons/bs';
import { NavigationPill } from '../../../uiComponents/layouts/navigationPill/navigationPill';
import { OptionList } from '../../../utils/props';
import { NavigationPillContainer } from './IncludeExcludeVehiclesForm.styles';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { useNavigate } from 'react-router-dom';
import { VEHICLES } from '../../../consts/routes';
import { createInsurancePolicyVehicles } from '../../../api/post/insurance.post';
import { Notification } from '../../../uiComponents/toast/toast';

interface IncludeExcludeVehiclesFormProps {
  policyId: string;
  vehicles: PolicyVehiclesPayload[];
  onClose: () => void;
}

export const IncludeExcludeVehiclesForm = ({ vehicles, policyId, onClose }: IncludeExcludeVehiclesFormProps) => {
  const navigate = useNavigate();
  const [vehiclesList, setVehiclesList] = useState<PolicyVehiclesPayload[]>(vehicles);
  const [showExcluded, setShowExcluded] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [selected, setSelected] = useState<OptionList>({
    value: 'included',
    label: 'Included',
  } as OptionList);
  const {
    setTotalRows,
    setTableData,
    goToPageNumber,
    setNumRowsPerPage,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetPolicyVehiclesList = useCallback(
    (count: number, vehicles: PolicyVehiclesPayload[]) => {
      const toogleExcluded = (vrm: string) => {
        const vehicles: PolicyVehiclesPayload[] = [];
        vehiclesList.forEach((vehicle) => {
          if (vehicle.vrm === vrm) {
            vehicle.excluded = !vehicle.excluded;
            vehicles.push(vehicle);
          } else {
            vehicles.push(vehicle);
          }
        });
        setVehiclesList(vehicles);
      };

      const vehiclesData = vehicles?.map((vehicle: PolicyVehiclesPayload) => {
        return {
          rowData: { data: vehicle, rowColour: PRIMARY_WHITE },
          cells: [
            <TableTextCell value={vehicle?.vrm} />,
            <TableTextCell value={vehicle?.current_insurance} />,
            <TableTextCell value={vehicle.driver_name} />,
            <TableTextCell value={vehicle.plan} />,
            <TableTextCell value={`${vehicle.manufacturer} ${vehicle.model}`} />,
            <TableTagCell tags={[vehicle.vehicle_status]} />,
            <FlexLayout gap={16}>
              <ActionIcon
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View vehicle"
                onClick={() => navigate(`${VEHICLES}/${vehicle.vehicle_id}`, { replace: false })}
              />
              {showExcluded && (
                <ActionIcon
                  icon={<BsFileEarmarkPlus size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Include from list"
                  onClick={() => toogleExcluded(vehicle?.vrm)}
                />
              )}
              {!showExcluded && (
                <ActionIcon
                  icon={<BsFileEarmarkX size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Exclude from list"
                  onClick={() => toogleExcluded(vehicle?.vrm)}
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(vehiclesData);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, showExcluded, navigate, vehiclesList]
  );

  const getPolicyVehiclesList = useCallback(
    (count: number, values: PolicyVehiclesPayload[]) => {
      handleGetPolicyVehiclesList(count, values);
    },
    [handleGetPolicyVehiclesList]
  );

  const applyFilters = useCallback(
    (pageNumber: number, numRowsPerPage: number, excluded: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const excludedFilter = vehiclesList.filter((item) => item.excluded === excluded);
      const pagination = excludedFilter.slice(
        pageNumber * numRowsPerPage,
        pageNumber * numRowsPerPage + numRowsPerPage
      );
      getPolicyVehiclesList(excludedFilter.length, pagination);
    },
    [vehiclesList, getPolicyVehiclesList, setTableData, goToPageNumber]
  );

  useEffect(() => {
    applyFilters(pageNumber, numRowsPerPage, showExcluded);
  }, [applyFilters, pageNumber, numRowsPerPage, showExcluded]);

  const handleSelected = (option: OptionList) => {
    goToPageNumber(0);
    setSelected(option);
    setShowExcluded(option.value === 'excluded');
  };

  const addInsurancePolicyVehicles = () => {
    setIsProcessing(true);
    const allIncludedVehicles = vehiclesList.filter((vehicle) => !vehicle.excluded);
    const payloadData = allIncludedVehicles.map((vehicle) => {
      return {
        vehicle_id: vehicle.vehicle_id,
        driver_id: vehicle.driver_id,
      };
    });
    const payload = {
      vehicles: payloadData,
    } as VehiclesPolicyPayload;

    createInsurancePolicyVehicles(policyId, payload)
      .then(() => {
        setIsProcessing(false);
        onClose();
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Policy Vehicles have been successfully created',
          isAlert: true,
        });
      })
      .catch(() => {
        setIsProcessing(false);
        onClose();
        Notification({
          type: 'error',
          title: 'Erros',
          message: 'Error creating Policy Vehicles',
          isAlert: true,
        });
      });
  };

  return (
    <>
      <NavigationPillContainer>
        <NavigationPill
          selected={selected}
          options={[
            {
              value: 'included',
              label: 'Included',
            } as OptionList,
            {
              value: 'excluded',
              label: 'Excluded',
            } as OptionList,
          ]}
          onSelect={handleSelected}
        />
      </NavigationPillContainer>
      <Table
        columns={policyVehiclesColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, showExcluded);
        }}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(pageNumber, numRowsPerPage, showExcluded);
        }}
        sortingColumn={sortingColumn}
        filterQuery={filterQuery}
        sortAscending={sortAscending}
        onColumnHeaderClick={() => {}}
        variant="compact"
        embedded
        overflowY="auto"
        maxHeight="calc(80vh - 300px)"
      />
      <FlexLayout itemsX="end" styled={{ marginTop: 16 }}>
        <PrimaryButton onClick={addInsurancePolicyVehicles} isProcessing={isProcessing}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
