import moment from 'moment';
import * as XLSX from 'xlsx';
import { StyledTableTextCell } from './tableUtils.styles';
import { Tag } from '../../customComponents/tag/tag';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { PRIMARY_PURPLE, SECONDARY_GRAY_80, STATUS_RED } from '../../../common/styles/Colors';
import { OptionList } from '../../../utils/props';
import { statusColors } from '../../../common/utils';

export const DEFAULT_NUM_ROWS_PER_PAGE = 50;

export const TableTextCell = ({ value, color }: { value: string; color?: string }) => {
  return (
    <StyledTableTextCell variant="body7" color={color ?? SECONDARY_GRAY_80} weight={300}>
      {value}
    </StyledTableTextCell>
  );
};

export const TableNumberCell = ({ value, color }: { value: number; color?: string }) => {
  return (
    <StyledTableTextCell variant="body7" color={color ?? SECONDARY_GRAY_80} weight={300}>
      {value}
    </StyledTableTextCell>
  );
};

export const TableTagCell = ({
  tags,
  wrap,
  isWarning,
}: {
  tags?: string[] | null | JSX.Element[];
  wrap?: boolean;
  isWarning?: boolean;
}) => {
  return (
    <FlexLayout wrap={wrap} gap={8}>
      {tags ? (
        tags?.map((tag: string | JSX.Element, index: number) => (
          <Tag
            styled={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
            key={index}
            color={isWarning ? STATUS_RED : statusColors[tag as string] ?? PRIMARY_PURPLE}
            children={tag}
          />
        ))
      ) : (
        <></>
      )}
    </FlexLayout>
  );
};

export interface TableFilter {
  columnName: string;
  options: OptionList | OptionList[];
  clause?: '$in' | '$btw' | '$up' | '$eq';
}

export const getFilters = (filters: TableFilter[], sortingColumn: string | undefined, direction: 'ASC' | 'DESC') => {
  const queryString: string = sortingColumn ? `sort=${sortingColumn}:${direction}` : '';
  const filterQueries: string[] = [];
  filters?.map((filter: TableFilter) => {
    if (filter?.options && filter?.options?.constructor === Array && filter?.options?.length > 0) {
      const filterOptions = filter?.options?.filter((f) => f?.value !== 'all');

      if (filterOptions.length > 0) {
        filterQueries.push(
          `filter=${filter?.columnName}${filter?.clause ?? '$in'}=${filterOptions
            ?.map((option: OptionList) => {
              return option?.value;
            })
            .join(',')}`
        );
      }
    } else {
      const singleFilter = filter?.options as OptionList;
      if (singleFilter?.value && singleFilter?.value !== 'all') {
        filterQueries?.push(`filter=${filter?.columnName}$eq=${singleFilter?.value}`);
      }
    }
  });

  return queryString + (filterQueries.length > 0 ? '&' + filterQueries?.map((value: string) => value).join('&') : '');
};

export const getQueryString = (
  tableFilters: TableFilter[],
  numRowsPerPage: number,
  pageNumber: number,
  searchString: string,
  sortingColumn: string | undefined,
  sortAscending: boolean
) => {
  const filterString: string = getFilters(tableFilters, sortingColumn, sortAscending ? 'ASC' : 'DESC');
  const skipQuery: string = pageNumber > 0 ? `&skip=${Math.round(pageNumber * numRowsPerPage)}` : '';
  const searchQuery: string = searchString ? (searchString?.length > 0 ? `&search=${searchString}` : '') : '';
  const queryString: string = `limit=${numRowsPerPage}${searchQuery}${skipQuery}`;
  return queryString + (filterString !== '' ? `&${filterString}` : '');
};

export const downloadXLSX = (downloadData: any[], fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(downloadData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, `${fileName}`);
  XLSX.writeFile(wb, `${fileName}-${moment().format('DD MMM YYYY')}.xlsx`);
};
