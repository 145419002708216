import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SectionHeader } from './sectionHeader';
import { SectionProps } from '../order/order';
import { Insurance } from '../order/order.styles';
import { CAR_HIRE_NATIONAL } from '../../../consts';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { UploaderInput } from '../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { Text } from '../../../uiComponents/text/text';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { Checkbox, CheckboxLabel } from '../../../uiComponents/uiControls/checkbox/checkbox';
import { updateApplication } from '../../../api/patch/application.patch';
import { uploadInsurance } from '../../../api/patch/vehicle.patch';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { handleAPIError, ErrorType } from '../../../utils/handleAPIError';
import { validateFutureDateFormat } from '../../../utils/validations';
import { fileToBase64, getBlob } from '../../../utils/utils';

interface ProofOfInsurance {
  insurance_document: File | Blob;
  expiry_date: string;
}

export const InsuranceSection = ({ isComplete, isLocked, progress, checkProgress }: SectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [ottoInsuranceChecked, setOttoInsuranceChecked] = useState<boolean>(progress?.is_otto_insured ?? false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<ProofOfInsurance>();
  const vehicleId: string = progress?.vehicle_id;
  const applicationId: string = progress?.application_id;

  const onSubmit = async (submitValues: ProofOfInsurance) => {
    setSubmitting(true);
    const { expiry_date, insurance_document } = submitValues;
    const payload = {
      application_id: vehicleId,
      insurance_type: 'ALTERNATIVE-INSURANCE',
      file: {
        fileName: 'INSURANCE',
        type: insurance_document?.type,
        contents: await fileToBase64(insurance_document as File),
        expiry_date,
      },
      expiry_date,
    };

    uploadInsurance(vehicleId, payload).then(() => {
      setSubmitting(false);
      checkProgress?.();
      setIsCollapsed(true);
    });
  };

  const onOttoInsuranceCheck = useCallback(
    (checked: boolean) => {
      updateApplication(applicationId, { is_otto_insured: checked }).then(() => {
        checkProgress?.();
      });
    },
    [applicationId, checkProgress]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (progress?.insurance_expiry != null) {
          setValue('expiry_date', progress?.insurance_expiry);
          setValue(
            'expiry_date',
            progress?.insurance_expiry ? moment(progress?.insurance_expiry)?.format('YYYY-MM-DD') : ''
          );
          if (progress?.insurance_s3_url) {
            const blob = getBlob(progress?.insurance_s3_url);
            setValue('insurance_document', await blob);
          }
        }
      } catch (err) {
        handleAPIError(err as ErrorType);
      }
    };
    fetchData();
  }, [progress, setValue]);

  const insuranceDoc = watch('insurance_document');
  const expiry = watch('expiry_date');

  return (
    <CollapsiblePanel
      styled={{ marginTop: 16 }}
      header={
        <SectionHeader
          title="Insurance & PCO"
          isComplete={isComplete}
          completedText="Completed"
          isLocked={isLocked}
          expanded={false}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => {
        setIsCollapsed(!isCollapsed);
      }}
    >
      <div>
        <SectionHeader title="Insurance & PCO" isComplete={isComplete} isLocked={isLocked} expanded />
        {progress?.application_type === CAR_HIRE_NATIONAL && (
          <Insurance gap={8} itemsY="center">
            <Checkbox
              name="otto_insurance"
              defaultChecked={progress?.is_otto_insured ?? false}
              onCheck={(checked: boolean) => {
                onOttoInsuranceCheck(checked);
                setOttoInsuranceChecked(checked);
              }}
            />
            <CheckboxLabel label="Otto insurance included" />
          </Insurance>
        )}
        {!ottoInsuranceChecked && (
          <>
            <Text styled={{ marginTop: 24 }} block variant="body6" color={PRIMARY_PURPLE} weight={300}>
              Please make sure its comprenhensive and compliant.
            </Text>
            <FlexLayout styled={{ marginTop: 24 }} itemsX="start" itemsY="center" gap={50}>
              <FlexLayout itemsX="start" gap={16} vertical>
                <UploaderInput
                  label="Proof of insurance"
                  control={control as unknown as Control<FieldValues>}
                  name="insurance_document"
                  required
                  error={errors?.insurance_document}
                />
                {errors?.insurance_document && (
                  <Text variant="body8" color={STATUS_RED} weight={300} block>
                    {errors?.insurance_document?.message ?? ''}
                  </Text>
                )}
                {progress?.insurance_s3_url && (
                  <Link style={{ color: 'blue !important' }} target="_blank" to={`${progress?.insurance_s3_url}`}>
                    View current insurance
                  </Link>
                )}
              </FlexLayout>
              <TextField
                type="date"
                {...register('expiry_date', {
                  required: 'Insurance expiry date is a required field',
                  validate: (v: string) => validateFutureDateFormat(v),
                })}
                error={errors?.expiry_date}
                required
                label="Expiry date"
              />
            </FlexLayout>
            <FlexLayout styled={{ marginTop: 24 }} itemsX="end" gap={16}>
              <PrimaryButton
                disabled={!insuranceDoc || !expiry || !!errors?.expiry_date}
                isProcessing={submitting}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </PrimaryButton>
            </FlexLayout>
          </>
        )}
      </div>
    </CollapsiblePanel>
  );
};
