import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { MdOutlineError } from 'react-icons/md';
import { AxiosError } from 'axios';
import moment from 'moment';
import { LoadingMask, OrderSections, InfoBanner } from './order.styles';
import { DriverDetailsSection } from '../sections/driverDetailsSection/driverDetailsSection';
import { AssessmentSection } from '../sections/assessmentSection';
import { PlanSection } from '../sections/planSection';
import { ModelSection } from '../sections/modelSection';
import { AssignVehicleSection } from '../sections/assignVehicleSection/assignVehicleSection';
import { PaymentMethodSection } from '../sections/paymentMethodSection';
import { DownpaymentSection } from '../sections/downpaymentSection';
import { DeclarationSection } from '../sections/declarationSection/declarationSection';
import { InsuranceSection } from '../sections/insuranceSection';
import { EvPackagesSection } from '../sections/evPackagesSection/evPackagesSection';
import { DvlaCheckSection } from '../sections/dvlaCheckSection';
import { PaymentStartDateSection } from '../sections/paymentStartDateSection';
import {
  CAR_HIRE_NATIONAL,
  COMPLETED,
  DOWNPAYMENT_SUCCEEDED,
  IN_SERVICE_RENTAL,
  LEUMI,
  PAYMENT_GATEWAY_LEUMI,
  PAYMENT_GATEWAY_OTTO_CAR,
  isR2BNational,
  isBurst,
  isR2BNew,
} from '../../../consts';
import { AGREEMENT_FROZEN, AGREEMENT_LIVE } from '../../../consts/agreement';
import { APPLICATION_COMPLETED, APPLICATION_SCRIVE_SENT } from '../../../consts/application';
import { AGREEMENTS, CREATE_TRANSFER, ORDERS } from '../../../consts/routes';
import { PageContainer } from '../../../core/coreStyles';
import { DriverInfoCard } from '../../../pages/drivers/driver/driverInfoCard/driverInfoCard';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Text } from '../../../uiComponents/text/text';
import { Notification } from '../../../uiComponents/toast/toast';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import { getVehicleMotExpiry } from '../../../api/get/vehicle.get';
import { progressChecker } from '../../../api/get/application.get';
import { createAgreement } from '../../../api/post/agreement.post';
import { goLive } from '../../../api/post/application.post';
import { updateOrdwayCustomerGateway } from '../../../api/post/driver.post';
import { setPlan } from '../../../api/patch/application.patch';
import { updateVehicle } from '../../../api/patch/vehicle.patch';
import { cancelAgreement } from '../../../api/delete/agreement.delete';
import { cancelApplication } from '../../../api/delete/application.delete';
import { AgreementType } from '../../../models/agreement';
import { ApplicationProgress, ApplicationSections, DownpaymentStatus } from '../../../models/application';
import { R2BBurst } from '../sections/burstSection';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { OptionList } from '../../../utils/props';
const cannotGoLiveText: string =
  "This application cannot be completed until the driver's current live agreement is terminated. If you intended to assign this driver an in-service rental, cancel this order and create a new order from the servicing page.";

export interface SectionProps {
  isComplete: boolean;
  isLocked: boolean;
  progress: ApplicationProgress;
  checkProgress?: () => void;
}

const getProgressValue = (orderSections: ApplicationSections, applicationComplete: boolean) => {
  const totalSections: number = Object.keys(orderSections)?.length;
  const completedSections: number = Object.values(orderSections)?.filter((section) => !!section)?.length;
  const progress: number = (completedSections / totalSections) * 100;
  if (applicationComplete) {
    return progress - 5;
  }
  return progress;
};

export const Order = () => {
  const navigate = useNavigate();
  const { applicationDetails, prevRoute } =
    (useLocation()?.state as { applicationDetails: ApplicationProgress; prevRoute: string }) ?? {};
  const { applicationId } = useParams();
  const [progress, setProgress] = useState<ApplicationProgress>();
  const [sections, setSections] = useState<ApplicationSections>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [gettingMotData, setGettingMotData] = useState<boolean>(false);
  const [modal, setModal] = useState<JSX.Element>();
  const [selectedPaymentStartDate, setSelectedPaymentStartDate] = useState<OptionList | undefined>(
    progress?.available_payment_start_dates?.[0]
  );
  const deposit: number = +(progress?.deposit ?? '0');
  const downpaymentAmount: number = +(progress?.downpayment_amount ?? '0');
  const finalDownpaymentStatus: DownpaymentStatus | undefined = progress?.final_downpayment_status;
  const finalDownpaymentComplete: boolean = finalDownpaymentStatus === DOWNPAYMENT_SUCCEEDED;
  const downpaymentSucceeded: boolean = progress?.downpayment_status === DOWNPAYMENT_SUCCEEDED;
  const showFinalDownpayment: boolean = downpaymentSucceeded && deposit > downpaymentAmount;
  const applicationType: AgreementType | undefined = progress?.application_type;
  const scriveSent: boolean = progress?.application_status === APPLICATION_SCRIVE_SENT && progress?.scrive_id != null;
  const [paymentMethodMissing, setPaymentMethodMissing] = useState<boolean>(false);
  const planSectionComplete: boolean = sections?.plan ?? false;
  const cannotGoLive: boolean =
    progress?.application_status !== APPLICATION_COMPLETED &&
    (progress?.current_agreement_status === AGREEMENT_LIVE ||
      progress?.current_agreement_status === AGREEMENT_FROZEN) &&
    applicationType !== IN_SERVICE_RENTAL;

  const confirmedPaymentStartDate = progress?.payment_start_date
    ? new Date(progress.payment_start_date)?.toLocaleString('default', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    : undefined;

  const [blockOrderCreation, setBlockOrderCreation] = useState<boolean>(false);

  const checkProgress = useCallback(
    (isGoingLive?: boolean) => {
      const makeAgreementLive = (progressData: ApplicationProgress) => {
        if (isR2BNew(progressData?.application_type ?? '')) {
          const goingLiveModal: JSX.Element = (
            <ConfirmationModal
              title="Scrive has been signed by all parties and agreement will now go live!"
              confirmButtonCaption="OK"
              isOpen
              onClose={() => setModal(undefined)}
              closeButtonCaption="Cancel"
              onConfirm={() => {
                setIsFetching(true);
                setModal(undefined);
                goLive({ application_id: progressData?.application_id ?? '' })
                  .then(() => {
                    Notification({
                      type: 'success',
                      title: 'Success',
                      message: 'The agreement is now live!',
                    });
                    checkProgress();
                  })
                  .catch((e: AxiosError<Error>) => {
                    Notification({
                      type: 'error',
                      title: 'Error',
                      message: e.message,
                    });
                    checkProgress();
                  });
              }}
            />
          );
          setModal(goingLiveModal);
        } else {
          const conditionReportModal: JSX.Element = (
            <ConfirmationModal
              isOpen
              title="Condition report is required before going live."
              confirmButtonCaption="OK"
              onClose={() => setModal(undefined)}
              onConfirm={() => {
                setModal(undefined);
                navigate(CREATE_TRANSFER, {
                  state: {
                    application_id: progressData?.application_id,
                    reason: 'COLLECTION-NEW',
                    dynamic: 'newUsedAgreement',
                    vrm: progressData?.vrm,
                    agreement_id: progressData?.agreement_id,
                  },
                });
              }}
              closeButtonCaption="Cancel"
            />
          );
          setModal(conditionReportModal);
        }
      };

      const goLiveAgreement = (progressData: ApplicationProgress) => {
        if (progressData?.agreement_id && progressData?.application_status === APPLICATION_COMPLETED) {
          const params = { agreement_id: progressData?.agreement_id };
          navigate(`${AGREEMENTS}/${progressData?.agreement_id}`, { state: params });
        } else if (
          // Check to see if we need to change payment gateway
          progressData?.vehicle_source.toLowerCase() !== progressData?.ordway_customer_payment_gateway?.toLowerCase()
        ) {
          // Need to store the application ID - so that after we get the payment details and refresh the page, the Cancel agreement button is disabled
          localStorage.setItem('application_going_live', progressData?.application_id ?? '');
          setIsFetching(true);
          updateOrdwayCustomerGateway({
            ordway_customer_id: progressData?.ordway_customer_id ?? '',
            gateway_account_name:
              progressData?.vehicle_source === LEUMI ? PAYMENT_GATEWAY_LEUMI : PAYMENT_GATEWAY_OTTO_CAR,
          }).then(() => {
            window.setTimeout(() => {
              checkProgress(true);
            }, 3000);
          });
        } else if (progressData?.ordwayData?.account_number) {
          makeAgreementLive(progressData);
        } else {
          setPaymentMethodMissing(true);
        }
      };

      if (applicationId) {
        setIsFetching(true);
        setPaymentMethodMissing(false);
        progressChecker(applicationId).then(
          (response: { data: { progress: ApplicationProgress; sections: ApplicationSections } }) => {
            const paymentStartDate = response?.data?.progress?.payment_start_date;
            setProgress(response?.data?.progress);
            setSections(response?.data?.sections);
            setIsFetching(false);

            if (paymentStartDate) {
              setSelectedPaymentStartDate({
                value: paymentStartDate,
                label: `${moment(paymentStartDate)?.format('dddd')} ${moment(paymentStartDate)?.format('DD MMM YYYY')}`,
              });
            } else {
              setSelectedPaymentStartDate(response?.data?.progress?.available_payment_start_dates?.[0]);
            }

            if (isGoingLive && !response?.data?.progress?.ordwayData?.account_number) {
              setPaymentMethodMissing(true);
            } else if (
              response?.data?.progress?.scrive_id != null &&
              response?.data?.progress?.scrive_signed &&
              response?.data?.progress?.application_status !== APPLICATION_COMPLETED &&
              response?.data?.progress?.ordwayData?.account_number
            ) {
              goLiveAgreement(response?.data?.progress);
            }
          }
        );
      }
    },
    [applicationId, navigate]
  );

  const sendScriveAndCreateAgreement = useCallback(() => {
    setIsFetching(true);
    setModal(undefined);
    createAgreement({
      application_id: applicationId ?? '',
      payment_start_date: selectedPaymentStartDate?.value ?? '',
    })
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Scrive sent to both parties.',
        });
        checkProgress();
      })
      .catch((e: AxiosError<Error>) => {
        Notification({
          type: 'error',
          title: 'Error',
          message: e.message,
        });
        checkProgress();
      });
  }, [applicationId, checkProgress, selectedPaymentStartDate]);

  const getScriveButtonText = useCallback(() => {
    if (isR2BNational(applicationType ?? '')) {
      return `Payment start date will be ${
        confirmedPaymentStartDate ?? selectedPaymentStartDate?.label
      } and cannot be changed after scrive is sent. Send scrive to parties for signing?`;
    }
    return 'Send scrive to parties for signing?';
  }, [confirmedPaymentStartDate, selectedPaymentStartDate, applicationType]);

  const handleSendScrivePopup = useCallback(() => {
    const scriveModal: JSX.Element = (
      <ConfirmationModal
        onClose={() => setModal(undefined)}
        confirmButtonCaption="Yes"
        closeButtonCaption="No"
        title={getScriveButtonText()}
        isOpen
        onConfirm={() => {
          sendScriveAndCreateAgreement();
        }}
      />
    );
    setModal(scriveModal);
  }, [getScriveButtonText, sendScriveAndCreateAgreement]);

  const sendScrive = useCallback(async () => {
    if (!progress?.vrm) {
      Notification({
        type: 'error',
        title: 'Error',
        message: 'Cannot send scrive without a VRM assigned to the vehicle.',
      });
    } else if (!progress?.mot_exp || moment(progress?.mot_exp).isBefore(moment())) {
      setGettingMotData(true);
      getVehicleMotExpiry(progress?.vehicle_id).then((response: { data: { mot_exp: string } | null }) => {
        setGettingMotData(false);
        if (response?.data?.mot_exp && moment(response?.data?.mot_exp).isAfter(progress?.mot_exp)) {
          // Only if the mot recieved from api is not null or is after the exp saved in the db
          // We then update the vehicle with its new mot expiry and then send scrive as normal
          updateVehicle(progress?.vehicle_id, { mot_exp: response?.data?.mot_exp })
            .then(() => {
              return true;
            })
            .catch(() => {
              checkProgress();
            });
        } else {
          const motModal: JSX.Element = (
            <ConfirmationModal
              onClose={() => setModal(undefined)}
              confirmButtonCaption="Yes"
              closeButtonCaption="No"
              title="A valid MOT expiry date could not be found. Would you like to proceed?"
              isOpen
              onConfirm={() => {
                sendScriveAndCreateAgreement();
              }}
            />
          );
          setModal(motModal);
        }
      });
    } else {
      // None expired vehicles
      handleSendScrivePopup();
    }
  }, [checkProgress, progress, handleSendScrivePopup, sendScriveAndCreateAgreement]);

  const cancelScrive = useCallback(() => {
    const cancelScriveModal: JSX.Element = (
      <ConfirmationModal
        closeButtonCaption="No"
        confirmButtonCaption="Yes"
        onClose={() => setModal(undefined)}
        title="Are you sure you want to cancel the pending scrive agreement?"
        isOpen
        onConfirm={() => {
          setIsFetching(true);
          setModal(undefined);
          cancelAgreement(applicationId ?? '')
            .then(() => {
              Notification({
                type: 'success',
                title: 'Success',
                message: 'Scrive successfully cancelled.',
              });
              checkProgress();
            })
            .catch((e: AxiosError<Error>) => {
              Notification({
                type: 'error',
                title: 'Error',
                message: e.message,
              });
              checkProgress();
            });
        }}
      />
    );
    setModal(cancelScriveModal);
  }, [applicationId, checkProgress]);

  useEffect(() => {
    if (applicationDetails?.application_type === IN_SERVICE_RENTAL && applicationId && !planSectionComplete) {
      setPlan(applicationId, { application_type: IN_SERVICE_RENTAL }).then(() => {
        checkProgress();
      });
    }
  }, [applicationDetails, applicationId, checkProgress, planSectionComplete]);

  useEffect(() => {
    checkProgress();
  }, [checkProgress]);

  const driver = {
    id: progress?.driver_id ?? '-',
    title: progress?.driver_title ?? '',
    first_name: progress?.driver_first_name ?? '-',
    middle_name: progress?.driver_middle_name ?? '',
    last_name: progress?.driver_last_name ?? '-',
    ordway_customer_id: progress?.ordway_customer_id,
    no_area_code: progress?.no_area_code,
    mobile_phone: progress?.mobile_phone ?? '-',
    pco_no: progress?.pco_document_no ?? '-',
    dvla_no: progress?.dvla_document_no ?? '-',
    uber_rating: +(progress?.uber_rating ?? '0'),
    tag_type: progress?.tag_type ?? [],
  };

  return (
    <>
      <PageContainer>
        <FlexLayout itemsX="end" gap={16}>
          {!sections?.downpayment && !isFetching && (
            <SecondaryButton
              onClick={() => {
                if (progress?.id) {
                  setModal(
                    <ConfirmationModal
                      confirmButtonCaption="Yes"
                      closeButtonCaption="No"
                      isOpen
                      title="Are you sure you want to cancel this order?"
                      preConfirm={() => cancelApplication(progress?.id).then(() => navigate(ORDERS))}
                      onClose={() => setModal(undefined)}
                    />
                  );
                }
              }}
              styled={{ width: 152 }}
            >
              Cancel
            </SecondaryButton>
          )}
          <SecondaryButton onClick={() => navigate(prevRoute ?? -1)} styled={{ width: 152 }}>
            Back
          </SecondaryButton>
        </FlexLayout>
        {!progress && (
          <FlexLayout itemsX="center">
            <Spinner size={24} color={PRIMARY_PURPLE} />
          </FlexLayout>
        )}
        {progress && sections && (
          <FlexLayout styled={{ marginTop: 16, marginBottom: 32 }} gap={16} itemsY="start">
            <DriverInfoCard
              driver={driver}
              orderProgress={getProgressValue(
                sections,
                progress?.agreement_id != null && progress?.application_status !== APPLICATION_COMPLETED
              )}
              setBlockOrderCreation={setBlockOrderCreation}
            />
            <OrderSections>
              {cannotGoLive && (
                <InfoBanner styled={{ marginTop: 0, marginBottom: 16 }} itemsX="center" itemsY="center" gap={16}>
                  <MdOutlineError color={STATUS_RED} size={24} />
                  <Text color={STATUS_RED} variant="body6" weight={500}>
                    {cannotGoLiveText}
                  </Text>
                </InfoBanner>
              )}
              <DvlaCheckSection
                isComplete={sections?.dvsa_check}
                isLocked={blockOrderCreation}
                progress={progress}
                checkProgress={() => checkProgress()}
              />
              <DriverDetailsSection
                isComplete={sections?.additional_details}
                isLocked={blockOrderCreation}
                progress={progress}
                checkProgress={() => checkProgress()}
              />
              {progress?.application_type !== IN_SERVICE_RENTAL && (
                <AssessmentSection
                  isComplete={sections?.assessment && sections.assessment_less_than_3_months}
                  isLocked={blockOrderCreation}
                  progress={progress}
                  assessmentLessThan3Months={sections?.assessment_less_than_3_months}
                  checkProgress={() => checkProgress()}
                />
              )}
              <PlanSection
                isComplete={sections?.plan}
                isLocked={
                  progress?.application_status === COMPLETED ||
                  scriveSent ||
                  applicationType === IN_SERVICE_RENTAL ||
                  blockOrderCreation
                }
                progress={progress}
                onPlanSelect={() => checkProgress()}
              />
              <ModelSection
                isComplete={sections?.choose_model}
                isLocked={progress?.application_status === COMPLETED || scriveSent || blockOrderCreation}
                progress={progress}
                checkProgress={() => checkProgress()}
                onModelSelect={() => checkProgress()}
              />
              <AssignVehicleSection
                isComplete={sections?.assign_vehicle}
                isLocked={!sections?.plan || scriveSent || progress?.application_status === COMPLETED}
                progress={progress}
                onAssignVehicle={() => checkProgress()}
              />
              <PaymentMethodSection
                paymentMethodMissing={paymentMethodMissing}
                isComplete={sections?.update_payment_method}
                isLocked={progress?.ordway_update_payment_method_url == null || blockOrderCreation}
                progress={progress}
                checkProgress={() => checkProgress()}
              />
              <DownpaymentSection
                isComplete={sections?.downpayment}
                isLocked={
                  !sections?.plan ||
                  !sections?.choose_model ||
                  !sections?.update_payment_method ||
                  sections?.downpayment
                }
                progress={progress}
                isFinalDownpayment={false}
                updateProgress={(progress: ApplicationProgress, sections: ApplicationSections) => {
                  setProgress(progress);
                  setSections(sections);
                }}
                checkProgress={() => checkProgress()}
              />
              <DeclarationSection
                isComplete={sections?.declaration}
                isLocked={!sections?.plan || !sections?.downpayment || sections?.declaration}
                progress={progress}
                checkProgress={() => checkProgress()}
              />
              {(isR2BNational(applicationType ?? '') || applicationType === CAR_HIRE_NATIONAL) && (
                <InsuranceSection
                  isComplete={
                    (progress?.insurance_expiry != null && progress?.insurance_s3_url != null) ||
                    !!progress?.is_otto_insured
                  }
                  isLocked={
                    !sections?.plan || !sections?.assign_vehicle || !sections?.downpayment || !sections?.declaration
                  }
                  progress={progress}
                  checkProgress={() => checkProgress()}
                />
              )}
              {isBurst(applicationType ?? '') ? (
                <R2BBurst
                  progress={progress}
                  isLocked={
                    blockOrderCreation ||
                    sections?.scrive ||
                    progress?.vehicle_id == null ||
                    progress?.vehicle_type_id == null ||
                    scriveSent
                  }
                  isComplete={sections?.evPackages}
                  checkProgress={() => checkProgress()}
                />
              ) : (
                <EvPackagesSection
                  isComplete={sections?.evPackages}
                  isLocked={blockOrderCreation || scriveSent || sections?.scrive}
                  progress={progress}
                  checkProgress={() => checkProgress()}
                />
              )}
              {(finalDownpaymentStatus === DOWNPAYMENT_SUCCEEDED || showFinalDownpayment) && (
                <DownpaymentSection
                  isComplete={finalDownpaymentComplete}
                  isLocked={
                    !sections?.plan ||
                    !sections?.update_payment_method ||
                    !sections?.downpayment ||
                    sections.finalDownPayment
                  }
                  progress={progress}
                  isFinalDownpayment
                  updateProgress={(progress: ApplicationProgress, sections: ApplicationSections) => {
                    setProgress(progress);
                    setSections(sections);
                  }}
                  checkProgress={() => checkProgress()}
                />
              )}
              {isR2BNational(applicationType ?? '') && (
                <PaymentStartDateSection
                  progress={progress}
                  onDateSelect={(option) => setSelectedPaymentStartDate(option)}
                  confirmedPaymentStartDate={confirmedPaymentStartDate}
                />
              )}
              {cannotGoLive ? (
                <InfoBanner itemsX="center" itemsY="center" gap={16}>
                  <MdOutlineError color={STATUS_RED} size={24} />
                  <Text color={STATUS_RED} variant="body6" weight={500}>
                    {cannotGoLiveText}
                  </Text>
                </InfoBanner>
              ) : (
                <>
                  {progress?.application_status !== 'SCRIVE-SENT' && progress?.application_status !== 'COMPLETED' && (
                    <InfoBanner itemsX="center" itemsY="center" gap={16}>
                      <MdOutlineError color={STATUS_RED} size={24} />
                      <Text color={STATUS_RED} variant="body6" weight={500}>
                        The scrive contract will be emailed to yourself (staff) to verify the insurance first, then the
                        driver will be required to sign, and then finally your own signature.
                      </Text>
                    </InfoBanner>
                  )}
                  <FlexLayout itemsX="end">
                    {scriveSent ? (
                      <SecondaryButton styled={{ marginTop: 16 }} onClick={() => cancelScrive()}>
                        Cancel scrive agreement
                      </SecondaryButton>
                    ) : (
                      <>
                        {progress?.application_status === APPLICATION_COMPLETED ? (
                          <FlexLayout
                            onClick={() => navigate(`${AGREEMENTS}/${progress?.agreement_id}`)}
                            gap={8}
                            styled={{ cursor: 'pointer', marginTop: 24, borderBottom: `1px solid ${PRIMARY_PURPLE}` }}
                          >
                            <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                              {`Agreement is live and subscription ${progress?.ordway_subscription_id} active!`}
                            </Text>
                            <FiChevronRight color={PRIMARY_PURPLE} size={20} />
                          </FlexLayout>
                        ) : (
                          <SecondaryButton
                            onClick={() => sendScrive()}
                            isProcessing={gettingMotData}
                            disabled={
                              !sections?.additional_details ||
                              !sections?.plan ||
                              !sections?.assessment ||
                              !sections?.assessment_less_than_3_months ||
                              !sections?.assign_vehicle ||
                              !sections?.downpayment ||
                              !sections?.declaration ||
                              !sections?.evPackages ||
                              !sections?.dvsa_check ||
                              (showFinalDownpayment && finalDownpaymentStatus !== DOWNPAYMENT_SUCCEEDED) ||
                              blockOrderCreation
                            }
                            styled={{ marginTop: 16, minWidth: 190 }}
                          >
                            Send scrive agreement
                          </SecondaryButton>
                        )}
                      </>
                    )}
                  </FlexLayout>
                </>
              )}
              {isFetching && (
                <LoadingMask itemsX="center" itemsY="center">
                  <Spinner color={PRIMARY_PURPLE} size={40} />
                </LoadingMask>
              )}
            </OrderSections>
          </FlexLayout>
        )}
      </PageContainer>
      {modal != null && modal}
    </>
  );
};
