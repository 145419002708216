import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { FiEdit } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import { GrNotes } from 'react-icons/gr';
import moment, { Moment } from 'moment';
import {
  HOLIDAY_CANCELLED_STATUS,
  HOLIDAY_COMPLETED_STATUS,
  HOLIDAY_UPCOMING_STATUS,
} from '../../../../consts/holiday';
import {
  FRIENDLY_FINANCE,
  R2B_CLASSIC,
  R2B_CLASSIC_PLUS_8,
  R2B_NEW_NATIONAL,
  R2B_NEW_NATIONAL_PLUS_8,
  R2B_USED,
  R2B_USED_BURST,
  R2B_USED_NATIONAL,
  R2B_USED_NATIONAL_PLUS_8,
  R2B_USED_PLUS_8,
} from '../../../../consts/plans';
import { LIVE_STATUS } from '../../../../consts/status';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { HolidayNotesText } from './driverHoliday.styles';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { HolidayTool } from '../../../../uiComponents/customComponents/holidayTool/holidayTool';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { ActionIcon } from '../../../../uiComponents/table/actionIcon/actionIcon';
import { NoResults } from '../../../../uiComponents/table/noResults/noResults';
import { Table } from '../../../../uiComponents/table/table';
import { TableRowGrid } from '../../../../uiComponents/table/table.styles';
import { paymentHolidayTableColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import {
  TableNumberCell,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../../uiComponents/text/text';
import { getDriverHolidays, getOrdwayDriverHolidayEgibility } from '../../../../api/get/driver.get';
import { getEmployee } from '../../../../api/get/employee.get';
import { cancelHoliday, cancelHolidayBatch as ctrlCancelHolidayBatch } from '../../../../api/patch/driver.patch';
import {
  DriverHoliday,
  CurrentPeriodHolidayDetails,
  DriverOrdwayHolidayEgibilityDetails,
  DriverPaymentHoliday,
  BookedHolidayDetails,
} from '../../../../models/holiday';
import { PRIMARY_PURPLE, SECONDARY_GRAY_20, SECONDARY_PURPLE_70 } from '../../../../common/styles/Colors';
import { renderNotification } from '../../../../utils/utils';

interface RenderDriverHolidayProps {
  holidayData: DriverPaymentHoliday;
  getDriverHolidaySummary: () => void;
  fetchData: () => void;
}

const RenderDriverHolidaysList = ({
  holidayData,
  getDriverHolidaySummary,
  fetchData,
}: RenderDriverHolidayProps): JSX.Element => {
  const [isIndividualHolidayModal, setIsIndividualHolidayModal] = useState<boolean>(false);
  const [selectedHoliday, setSelectedHoliday] = useState<DriverHoliday | null>(null);

  const cancelIndividualHoliday = useCallback(
    async (holiday: DriverHoliday) => {
      try {
        await cancelHoliday(holidayData?.id, holiday?.id);
        fetchData();
        getDriverHolidaySummary();
        renderNotification('success', 'Success', 'Successfully cancelled holiday batch');
        return true;
      } catch (err) {
        renderNotification('error', 'Error', `Failed to create driver’s tag: ${err}`, false);
      }
    },
    [fetchData, getDriverHolidaySummary, holidayData?.id]
  );

  return (
    <>
      <TableRowGrid $rowClickable={false} $borderColur={SECONDARY_GRAY_20} gap={8} template={4}>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Status
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Commencement date
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Actions
        </Text>
      </TableRowGrid>
      {holidayData && holidayData?.holidays?.length > 0 ? (
        <>
          {holidayData?.holidays?.map((holiday: DriverHoliday) => (
            <TableRowGrid $rowClickable={false} $borderColur={SECONDARY_GRAY_20} key={holiday?.id} gap={8} template={4}>
              <TableTagCell tags={[holiday?.holiday_status]} />
              <TableTextCell value={moment(holiday?.commencement_date).format('DD MMM YYYY')} />
              {holiday?.holiday_status === HOLIDAY_UPCOMING_STATUS && (
                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsIndividualHolidayModal(true);
                    setSelectedHoliday(holiday);
                  }}
                  icon={<GiCancel size={24} color={SECONDARY_PURPLE_70} />}
                  tooltip="Cancel individual holiday"
                />
              )}
            </TableRowGrid>
          ))}
        </>
      ) : (
        <NoResults />
      )}
      {selectedHoliday && (
        <ConfirmationModal
          title="Are you sure you want to cancel this individual holiday?"
          isOpen={isIndividualHolidayModal}
          onClose={() => {
            setIsIndividualHolidayModal(false);
            setSelectedHoliday(null);
          }}
          onConfirm={() => {
            cancelIndividualHoliday(selectedHoliday);
            setIsIndividualHolidayModal(false);
          }}
          confirmButtonCaption={'Yes'}
          closeButtonCaption={'No'}
        />
      )}
    </>
  );
};

interface DriverData {
  driverId: string;
  agreementType: string;
  agreementId: string;
  agreementStatus: string;
  ordwayCustomerId: string | null;
  ordwaySubscriptionId: string | null;
  driverName: string;
  tableHeader: string;
  holidayBatches?: DriverPaymentHoliday[];
  fetchAgreementData?: () => void;
}

export const DriverHolidays = ({
  driverId,
  tableHeader,
  agreementId,
  agreementStatus,
  agreementType,
  ordwayCustomerId,
  ordwaySubscriptionId,
  driverName,
  holidayBatches,
  fetchAgreementData,
}: DriverData): JSX.Element => {
  const showHoliday: boolean | string =
    agreementId &&
    agreementStatus === LIVE_STATUS &&
    [
      R2B_CLASSIC,
      R2B_NEW_NATIONAL,
      R2B_NEW_NATIONAL_PLUS_8,
      R2B_USED,
      R2B_USED_NATIONAL,
      R2B_USED_NATIONAL_PLUS_8,
      R2B_CLASSIC_PLUS_8,
      R2B_USED_PLUS_8,
      FRIENDLY_FINANCE,
      R2B_USED_BURST,
    ].includes(agreementType);
  const holidayTotal: number = [
    R2B_CLASSIC_PLUS_8,
    R2B_USED_PLUS_8,
    R2B_NEW_NATIONAL_PLUS_8,
    R2B_USED_NATIONAL_PLUS_8,
  ].includes(agreementType)
    ? 8
    : 4;

  const [employeeDetails, setEmployeeDetails] = useState<string>('');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isHolidayBatchModal, setIsHolidayBatchModal] = useState<boolean>(false);
  const [selectedHoliday, setSelectedHoliday] = useState<DriverPaymentHoliday | null>(null);
  const [holidayToEdit, setHolidayToEdit] = useState<DriverPaymentHoliday>();
  const [holidayData, setHolidayData] = useState<DriverPaymentHoliday[]>();
  const [holidayToolOpen, setHolidayToolOpen] = useState<boolean>(false);
  const [holidaySummary, setHolidaySummary] = useState<CurrentPeriodHolidayDetails>();
  const [holidayNotesOpen, setHolidayNotesOpen] = useState<boolean>(false);
  const [holidayNotes, setHolidayNotes] = useState<string>('');
  const notes: string[] = holidayNotes?.split('\n');
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    setSearchString,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const getSummaryStatus = ({ holidays }: DriverPaymentHoliday): string => {
    if (holidays?.some((holiday) => holiday.holiday_status === HOLIDAY_UPCOMING_STATUS)) {
      return HOLIDAY_UPCOMING_STATUS;
    }
    if (holidays?.some((holiday) => holiday.holiday_status === HOLIDAY_COMPLETED_STATUS)) {
      return HOLIDAY_COMPLETED_STATUS;
    }
    return HOLIDAY_CANCELLED_STATUS;
  };

  const getHolidaySummary = useCallback((): void => {
    if (ordwayCustomerId) {
      getOrdwayDriverHolidayEgibility(ordwayCustomerId).then(
        (response: { data: DriverOrdwayHolidayEgibilityDetails }) => {
          if (response?.data) {
            setHolidaySummary(response?.data?.currentPeriodHoliday);
          }
        }
      );
    }
  }, [ordwayCustomerId]);

  const getBookedHolidayDates = (holidayData: DriverPaymentHoliday[]): BookedHolidayDetails[] => {
    const bookedDates: BookedHolidayDetails[] = [];
    holidayData?.forEach((batch) => {
      batch?.holidays?.forEach((holiday) => {
        if (holiday.holiday_status !== HOLIDAY_CANCELLED_STATUS) {
          bookedDates.push({ holiday_batch_id: batch?.id, commencement_date: holiday?.commencement_date });
        }
      });
    });
    return bookedDates;
  };

  const getEmployeeDetails = useCallback(async ({ employee_id, updated_date }: DriverPaymentHoliday): Promise<void> => {
    const employee = await getEmployee(employee_id);

    setEmployeeDetails(
      `${employee?.data?.first_name} ${employee?.data?.last_name} on ${moment(updated_date).format(
        'DD MMM YYYY HH:mm:ss'
      )}`
    );
  }, []);

  const isBeforeDeadline = ({ commencement_date }: DriverPaymentHoliday): boolean => {
    const holidayStartDate: Date = new Date(commencement_date);
    const today: Date = new Date();
    const deadline: Moment = moment(holidayStartDate).subtract(1, 'days');

    if (moment(today).isBefore(deadline, 'day') || (moment(today).isSame(deadline, 'day') && today.getHours() < 19)) {
      return true;
    }
    return false;
  };

  const handleGetDriverPaymentHolidayResponse = useCallback(
    (driverHolidays: DriverPaymentHoliday[]): void => {
      const driverHolidayRows = driverHolidays?.map((driverHoliday: DriverPaymentHoliday) => {
        const finalholidayWeek: DriverHoliday | undefined = driverHoliday?.holidays
          ?.filter((holiday) => holiday.holiday_status !== HOLIDAY_CANCELLED_STATUS)
          ?.at(-1);
        const batchHasUpcoming: boolean = driverHoliday?.holidays?.some(
          (holiday) => holiday.holiday_status === HOLIDAY_UPCOMING_STATUS
        );
        const batchHasCancelled: boolean = driverHoliday?.holidays?.some(
          (holiday) => holiday.holiday_status === HOLIDAY_CANCELLED_STATUS
        );
        return {
          rowData: { data: driverHoliday },
          cells: [
            <TableTextCell
              value={`${driverHoliday.vrm} ${driverHoliday.agreement_type} (${driverHoliday.agreement_status})`}
              color={SECONDARY_PURPLE_70}
            />,
            <TableTextCell
              value={moment(driverHoliday.commencement_date).format('DD/MM/YYYY')}
              color={SECONDARY_PURPLE_70}
            />,
            <TableTextCell
              value={
                finalholidayWeek
                  ? moment(finalholidayWeek.commencement_date).add(6, 'days').format('DD/MM/YYYY')
                  : 'N/A'
              }
              color={SECONDARY_PURPLE_70}
            />,
            <TableNumberCell value={driverHoliday?.holiday_length} color={SECONDARY_PURPLE_70} />,
            <TableTagCell tags={[getSummaryStatus(driverHoliday)]} />,
            <FlexLayout gap={16}>
              {isBeforeDeadline(driverHoliday) &&
                batchHasUpcoming &&
                driverHoliday?.agreement_status === LIVE_STATUS &&
                !batchHasCancelled && (
                  <ActionIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setHolidayToEdit(driverHoliday);
                      setHolidayToolOpen(true);
                    }}
                    icon={<FiEdit size={24} color={PRIMARY_PURPLE} />}
                    tooltip="Edit holiday batch"
                  />
                )}

              {isBeforeDeadline(driverHoliday) &&
                batchHasUpcoming &&
                driverHoliday?.agreement_status === LIVE_STATUS && (
                  <ActionIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsHolidayBatchModal(true);
                      setSelectedHoliday(driverHoliday);
                    }}
                    icon={<GiCancel size={24} color={PRIMARY_PURPLE} />}
                    tooltip="Cancel holiday batch"
                  />
                )}

              {driverHoliday?.override_reason && (
                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setHolidayNotes(driverHoliday?.override_reason ?? '');
                    setHolidayNotesOpen(true);
                    getEmployeeDetails(driverHoliday);
                  }}
                  icon={<GrNotes size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Holiday notes"
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(driverHolidayRows);
      setTotalRows(driverHolidayRows.length);
      setHolidayData(driverHolidays);
    },
    [setTotalRows, setTableData, getEmployeeDetails]
  );

  const fetchDriverPaymentHolidayList = useCallback(
    (queryString: string): void => {
      if (holidayBatches) {
        handleGetDriverPaymentHolidayResponse(holidayBatches);
      } else {
        getDriverHolidays(queryString).then((response: { data: DriverPaymentHoliday[] }) => {
          handleGetDriverPaymentHolidayResponse(response.data);
        });
      }
      if (ordwayCustomerId) {
        getHolidaySummary();
      }
    },
    [handleGetDriverPaymentHolidayResponse, getHolidaySummary, holidayBatches, ordwayCustomerId]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean
    ): void => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchDriverPaymentHolidayList(`${driverId}?${queryString}`);
    },
    [fetchDriverPaymentHolidayList, setTableData, goToPageNumber, tableFilters, driverId]
  );

  const cancelHolidayBatch = useCallback(
    async (holiday: DriverPaymentHoliday) => {
      try {
        await ctrlCancelHolidayBatch(holiday?.id);
        if (holidayBatches) {
          fetchAgreementData?.();
        } else {
          getDriverHolidays(driverId).then((response: { data: DriverPaymentHoliday[] }) => {
            handleGetDriverPaymentHolidayResponse(response.data);
          });
        }
        getHolidaySummary();
        renderNotification('success', 'Success', 'Successfully canceled holiday batch');
        return true;
      } catch (err) {
        renderNotification('error', 'Error', `Failed to cancel the holiday batch with error: ${err}`, false);
      }
    },
    [driverId, fetchAgreementData, getHolidaySummary, handleGetDriverPaymentHolidayResponse, holidayBatches]
  );

  useEffect(() => {
    if (tableData == null) {
      setSortingColumn('commencement_date');
      fetchDriverPaymentHolidayList(driverId);
    }
  }, [fetchDriverPaymentHolidayList, setSortingColumn, tableData, driverId]);

  useEffect(() => {
    setTableData(undefined);
  }, [setTableData, driverId]);

  return (
    <>
      <CollapsiblePanel
        header={
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
            Payment holidays
          </Text>
        }
        expanded={!isCollapsed}
        onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
        styled={{ marginTop: 16 }}
      >
        <div>
          <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
            {tableHeader}
          </Text>
          <FlexLayout style={{ marginBottom: '16px' }} itemsX="space-between">
            <div>
              {holidaySummary && showHoliday && (
                <FieldGrid
                  numColumns={3}
                  headers={['Total weeks:', 'Remaining weeks:', 'Weeks taken:']}
                  values={[
                    holidayTotal,
                    holidaySummary?.numberOfWeeksTaken,
                    holidayTotal - holidaySummary?.numberOfWeeksTaken < 0
                      ? 0
                      : holidayTotal - holidaySummary?.numberOfWeeksTaken,
                  ]}
                />
              )}
            </div>
            {showHoliday && (
              <FlexLayout style={{ height: '40px' }} itemsX="end">
                <PrimaryButton onClick={() => setHolidayToolOpen(true)}>Book holidays</PrimaryButton>
              </FlexLayout>
            )}
          </FlexLayout>
          <Table
            variant="compact"
            tableTheme="purple"
            embedded
            header={tableHeader}
            onColumnHeaderClick={(columnId: string) =>
              applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
            }
            sortAscending={sortAscending}
            columns={paymentHolidayTableColumns}
            rows={tableData}
            totalRows={totalRows}
            rowsPerPage={numRowsPerPage}
            currentPageNumber={pageNumber}
            sortingColumn={sortingColumn}
            filters={[]}
            getTableRowData={(id: string) => {
              const expandedDriverHoliday = tableData?.filter(
                (holidayBatch) => holidayBatch.rowData.data.id === id
              )?.[0].rowData.data;
              return Promise.resolve(
                <RenderDriverHolidaysList
                  // @ts-ignore
                  holidayData={expandedDriverHoliday}
                  getDriverHolidaySummary={getHolidaySummary}
                  fetchData={() => (holidayBatches ? fetchAgreementData?.() : fetchDriverPaymentHolidayList(driverId))}
                />
              );
            }}
            onSearchChange={(value: string) => {
              setSearchString(value);
              applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
            }}
            filterQuery={filterQuery}
            goToPage={(pageNumber: number) => {
              goToPageNumber(pageNumber);
              applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
            }}
            onNumRowsPerPageChange={(value: number) => {
              setNumRowsPerPage(value);
              goToPageNumber(0);
              applyFilters(0, value, searchString, sortingColumn, sortAscending);
            }}
            styled={{ marginTop: 24 }}
          />
        </div>
      </CollapsiblePanel>

      <Modal
        title={holidayToEdit ? 'Edit payment holiday' : 'New payment holiday'}
        open={holidayToolOpen}
        onClose={() => {
          setHolidayToolOpen(false);
          setHolidayToEdit(undefined);
          if (holidayBatches) {
            fetchAgreementData?.();
          } else {
            fetchDriverPaymentHolidayList(driverId);
          }
          getHolidaySummary();
        }}
        showClose
      >
        <HolidayTool
          driverDetails={{
            ordway_customer_id: ordwayCustomerId,
            ordway_subscription_id: ordwaySubscriptionId,
            agreement_type: agreementType,
            id: driverId,
            agreement_id: agreementId,
            driver_name: driverName,
          }}
          holidayToEdit={holidayToEdit}
          bookedDates={getBookedHolidayDates(holidayData ?? [])}
        />
      </Modal>

      <Modal
        title="Holiday rules override details"
        open={holidayNotesOpen}
        onClose={() => setHolidayNotesOpen(false)}
        showClose
      >
        <HolidayNotesText>
          The following holiday rules were broken but have been overridden:
          {notes?.map((note, i) => (
            <Fragment key={note + i.toString()}>
              {note === '' ? <br /> : <HolidayNotesText>{note}</HolidayNotesText>}
            </Fragment>
          ))}
          <div>{`Authorised by ${employeeDetails}`}</div>
        </HolidayNotesText>
      </Modal>

      {selectedHoliday && (
        <ConfirmationModal
          title="Are you sure you want to cancel this holiday batch?"
          isOpen={isHolidayBatchModal}
          onClose={() => {
            setIsHolidayBatchModal(false);
            setSelectedHoliday(null);
          }}
          onConfirm={() => {
            cancelHolidayBatch(selectedHoliday);
            setIsHolidayBatchModal(false);
          }}
          confirmButtonCaption={'Yes'}
          closeButtonCaption={'No'}
        />
      )}
    </>
  );
};
