import { useCallback, useContext, useEffect, useState } from 'react';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Table } from '../../../uiComponents/table/table';
import { useTableFilters } from '../../../hooks/useTableFilters';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableFilter,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { getAllInsuranceCompanies, getAllInsurancePolices } from '../../../api/get/insurance.get';
import {
  InitiateDataValidationPayload,
  InitiateInsuranceRunPayload,
  InsuranceCompany,
  ModifiedInsurancePolicy,
  PolicyVehicles,
} from '../../../models/insurancePolicy';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { BsEye, BsPencil, BsArchive, BsPlayCircle } from 'react-icons/bs';
import { PRIMARY_PURPLE, PRIMARY_WHITE, STATUS_BLUE } from '../../../common/styles/Colors';
import { insurancePolicyColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { Modal } from '../../../uiComponents/modals/modal';
import { CreateEditPolicyForm } from '../createEditPolicyForm/createEditPolicyForm';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Notification } from '../../../uiComponents/toast/toast';
import { deleteInsurancePolicy } from '../../../api/delete/insurance.delete';
import { APP_CONTEXT } from '../../../utils/context';
import moment from 'moment';
import { ViewReviewPolicyForm } from '../viewReviewPolicyForm/viewReviewPolicyForm';
import { isDateWithinOneWeekBefore } from '../../../utils/utils';
import { initiateInsuranceDataValidation, initiateInsuranceRunProcess } from '../../../api/post/insurance.post';
import { DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { FilterInput } from '../../../uiComponents/table/tableFilters/tableFilters.styles';
import { Text } from '../../../uiComponents/text/text';
import { OptionList } from '../../../utils/props';
import { FilterOptionSection } from './policyList.styles';
import { IncludeExcludeVehiclesForm } from '../IncludeExcludeVehiclesForm/IncludeExcludeVehiclesForm';

export const PoliciesList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [policyValues, setPolicyValues] = useState<ModifiedInsurancePolicy | undefined>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditPolicy, setIsEditPolicy] = useState<boolean>(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [isViewPolicy, setIsViewPolicy] = useState<boolean>(false);
  const [isRenewPolicy, setIsRenewPolicy] = useState<boolean>(false);
  const [policyVehiclesRangeModalOpen, setPolicyVehiclesRangeModalOpen] = useState<boolean>(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false);
  const [isInitiateDataValidationModalOpen, setIsInitiateDataValidationModalOpen] = useState<boolean>(false);
  const [isInitiateInsuranceRunModalOpen, setIsInitiateInsuranceRunModalOpen] = useState<boolean>(false);
  const [policyNumber, setPolicyNumber] = useState<string>('');
  const [insuranceCompany, setInsuranceCompany] = useState<OptionList[]>([]);
  const [policyStartDate, setPolicyStartDate] = useState<string>('');
  const [policyEndDate, setPolicyEndDate] = useState<string>('');
  const [policyStatus, setPolicyStatus] = useState<OptionList[]>([]);
  const [insuranceCompanyOptions, setInsuranceCompanyOptions] = useState<OptionList[]>([]);
  const [allInsurancePolicies, setAllInsurancePolicies] = useState<ModifiedInsurancePolicy[]>([]);
  const [policyVehicles, setPolicyVehicles] = useState<PolicyVehicles>();

  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortAscending,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetInsurancePoliciesResponse = useCallback(
    (count: number, policies: ModifiedInsurancePolicy[]) => {
      const policyRows = policies?.map((policy: ModifiedInsurancePolicy) => {
        return {
          rowData: { data: policy, rowColour: PRIMARY_WHITE },
          cells: [
            <TableTextCell value={policy?.policy_number} />,
            <TableTextCell value={policy?.insurance_company} />,
            <TableTagCell tags={[policy.insurance_policy_status]} />,
            <TableTextCell value={moment(policy?.start_date).format('DD/MM/YYYY [at] HH:mm')} />,
            <TableTextCell value={moment(policy?.end_date).format('DD/MM/YYYY [at] HH:mm')} />,
            <TableTextCell value={policy?.vrm_number_plates?.join(', ')} />,
            <FlexLayout gap={16}>
              <ActionIcon
                onClick={() => {
                  setPolicyValues(policy);
                  setIsViewModalOpen(true);
                  setIsViewPolicy(true);
                }}
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View policy"
              />
              <ActionIcon
                onClick={() => {
                  setPolicyValues(policy);
                  setIsEditPolicy(true);
                  setIsModalOpen(true);
                }}
                icon={<BsPencil size={24} color={PRIMARY_PURPLE} />}
                tooltip="Edit policy"
              />

              <ActionIcon
                onClick={() => {
                  setPolicyValues(policy);
                  setIsConfirmDeleteModalOpen(true);
                }}
                icon={<BsArchive size={24} color={PRIMARY_PURPLE} />}
                tooltip="Archive policy"
              />

              {isDateWithinOneWeekBefore(policy.start_date) && (
                <ActionIcon
                  onClick={() => {
                    setPolicyValues(policy);
                    setIsInitiateInsuranceRunModalOpen(true);
                  }}
                  icon={<BsPlayCircle size={24} color={STATUS_BLUE} />}
                  tooltip="Insurance run"
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(policyRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchPolciesList = useCallback(
    (queryString: string) => {
      getAllInsurancePolices(queryString).then((response: { count: number; data: ModifiedInsurancePolicy[] }) => {
        setAllInsurancePolicies(response.data);
        handleGetInsurancePoliciesResponse(response.count, response.data);
      });
    },
    [handleGetInsurancePoliciesResponse]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean
    ) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchPolciesList(queryString);
    },
    [fetchPolciesList, setTableData, goToPageNumber, tableFilters]
  );

  const deletePolicy = async (id: string) => {
    await deleteInsurancePolicy(id)
      .then(() => {
        applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Policy has been successfully deleted',
          isAlert: true,
        });
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error while deleting policy',
          isAlert: true,
        });
      });
  };

  const initiateDataValidationCheck = async (policy: ModifiedInsurancePolicy) => {
    const payload: InitiateDataValidationPayload = {
      id: policy.id,
      body: {
        vrm_number_plates: policy.vrm_number_plates,
        insurance_policy_status: policy.insurance_policy_status,
        start_date: policy.start_date,
        policy_number: policy.policy_number,
      },
    };
    await initiateInsuranceDataValidation(payload).then(() => {
      Notification({
        type: 'success',
        title: 'Success',
        message:
          'Policy data review has been initiated, please keep a lookout for completion of the data review, well notify you via email',
        isAlert: true,
      });
    });
  };

  const initiateInsuranceRun = async (policy: ModifiedInsurancePolicy) => {
    const payload: InitiateInsuranceRunPayload = {
      id: policy.id,
      body: {
        vrm_number_plates: policy.vrm_number_plates,
        policy_start_date: policy.start_date,
        policy_end_date: policy.end_date,
        policy_date_format: policy.policy_date_format,
        policy_number: policy.policy_number,
        policy_permission_letter_url: policy.policy_permission_letter_url,
        policy_certificate_url: policy.policy_certificate_url,
        policy_insurance_company: policy.insurance_company,
      },
    };
    await initiateInsuranceRunProcess(payload).then(() => {
      Notification({
        type: 'success',
        title: 'Success',
        message:
          'Insurance run has been initiated, please keep a lookout for completion of the process, well notify you via email',
        isAlert: true,
      });
    });
  };

  const onClearClick = useCallback(() => {
    setPolicyNumber('');
    setInsuranceCompany([]);
    setPolicyStartDate('');
    setPolicyEndDate('');
    setPolicyStatus([]);
    fetchPolciesList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:DESC&filter=archived$eq=false`);
  }, [fetchPolciesList]);

  const getInsuranceCompanies = useCallback(async () => {
    const { data } = await getAllInsuranceCompanies();
    const formattedOptions = data.map((item: InsuranceCompany) => {
      return {
        value: item.id,
        label: item.name,
      } as OptionList;
    });
    setInsuranceCompanyOptions(formattedOptions);
  }, []);

  useEffect(() => {
    setActiveSideNav('policiestListPage');
    setPageTitle('Policies');
    setSortingColumn('start_date');
    setSortAscending(false);
    fetchPolciesList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:DESC&filter=archived$eq=false`);
    getInsuranceCompanies();
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending, fetchPolciesList, getInsuranceCompanies]);

  useEffect(() => {
    const filters: TableFilter[] = [
      {
        columnName: 'archived',
        options: { value: 'false', label: 'false' },
      },
      {
        columnName: 'policy_number',
        options: { value: policyNumber ?? '', label: policyNumber ?? '' },
      },
      { columnName: 'insurance_company', options: insuranceCompany },
    ];
    if (policyStartDate) {
      filters.push({
        columnName: 'start_date',
        options: [
          {
            label: `${policyStartDate} 00:00`,
            value: `${policyStartDate} 00:00`,
          },
          {
            label: `${policyStartDate} 23:59`,
            value: `${policyStartDate} 23:59`,
          },
        ],
        clause: '$btw',
      });
    }
    if (policyEndDate) {
      filters.push({
        columnName: 'end_date',
        options: [
          {
            label: `${policyEndDate} 00:00`,
            value: `${policyEndDate} 00:00`,
          },
          {
            label: `${policyEndDate} 23:59`,
            value: `${policyEndDate} 23:59`,
          },
        ],
        clause: '$btw',
      });
    }
    setTableFilters(filters);
  }, [setTableFilters, policyNumber, insuranceCompany, policyStartDate, policyEndDate, policyStatus]);

  const filters: FilterItem[] = [
    {
      name: 'policy_number',
      element: (
        <FilterOptionSection>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            Policy number:
          </Text>
          <FilterInput type="text" value={policyNumber} onChange={(e) => setPolicyNumber(e.target.value)} />
        </FilterOptionSection>
      ),
    },
    {
      name: 'insurance_company',
      element: (
        <FilterOptionSection>
          <DropDownFilter
            name="insurance_company"
            placeholder="Insurance company"
            options={insuranceCompanyOptions}
            title="Insurance company"
            multiValues={insuranceCompany}
            onChange={(items) => setInsuranceCompany(items as OptionList[])}
          />
        </FilterOptionSection>
      ),
    },
    {
      name: 'start_date',
      element: (
        <FilterOptionSection>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            Policy start
          </Text>
          <FilterInput type="date" value={policyStartDate} onChange={(e) => setPolicyStartDate(e.target.value)} />
        </FilterOptionSection>
      ),
    },
    {
      name: 'end_date',
      element: (
        <div>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            Policy end
          </Text>
          <FilterInput type="date" value={policyEndDate} onChange={(e) => setPolicyEndDate(e.target.value)} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        header="Policies list"
        actionButtonText="Add policy"
        onActionButtonClick={() => setIsModalOpen(true)}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={insurancePolicyColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(pageNumber, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllInsurancePolices}
        downloadName="Policies"
        dataDownloadMethod="download"
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={onClearClick}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, searchString, sortingColumn, sortAscending);
        }}
      />

      <Modal
        title={`${isEditPolicy ? 'Edit' : 'Add new'} policy`}
        open={isModalOpen}
        showClose
        onClose={() => {
          setIsModalOpen(false);
          setPolicyValues(undefined);
          isEditPolicy && setIsEditPolicy(false);
        }}
        styled={{ width: '60vw', minWidth: '600px' }}
      >
        <CreateEditPolicyForm
          allInsurancePolicies={allInsurancePolicies}
          isInEdit={isEditPolicy}
          values={policyValues}
          onClose={() => {
            setIsModalOpen(false);
            setPolicyValues(undefined);
            isEditPolicy && setIsEditPolicy(false);
          }}
          onFormSubmit={(data: PolicyVehicles) => {
            setPolicyVehicles(data);
            setIsModalOpen(false);
            setPolicyValues(undefined);
            isEditPolicy && setIsEditPolicy(false);
            setPolicyVehiclesRangeModalOpen(true);
          }}
          insuranceCompanyOptions={insuranceCompanyOptions}
        />
      </Modal>

      <Modal
        title={isRenewPolicy ? 'Renew policy' : 'View policy'}
        open={isViewModalOpen}
        showClose
        onClose={() => {
          setIsViewModalOpen(false);
          setIsRenewPolicy(false);
          setPolicyValues(undefined);
        }}
        styled={{ width: '60vw', minWidth: '600px' }}
      >
        <ViewReviewPolicyForm
          isInViewMode={isViewPolicy}
          renewPolicy={() => {
            setIsViewPolicy(false);
            setIsRenewPolicy(true);
          }}
          values={policyValues}
          onClose={() => {
            setIsViewModalOpen(false);
            setIsRenewPolicy(false);
            setPolicyValues(undefined);
          }}
          onFormSubmit={() => {
            setIsRenewPolicy(false);
            setIsViewModalOpen(false);
            setPolicyValues(undefined);
            applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
          insuranceCompanyOptions={insuranceCompanyOptions}
        />
      </Modal>

      <ConfirmationModal
        title={'Are you sure you want to archive this policy?'}
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => {
          setIsConfirmDeleteModalOpen(false);
          setPolicyValues(undefined);
        }}
        onConfirm={() => {
          if (policyValues?.id) {
            deletePolicy(policyValues.id);
            setIsConfirmDeleteModalOpen(false);
            setPolicyValues(undefined);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />

      <ConfirmationModal
        title={'Are you sure you want to initiate email data check for this policy?'}
        isOpen={isInitiateDataValidationModalOpen}
        onClose={() => {
          setIsInitiateDataValidationModalOpen(false);
          setPolicyValues(undefined);
        }}
        onConfirm={() => {
          if (policyValues) {
            initiateDataValidationCheck(policyValues);
            setIsInitiateDataValidationModalOpen(false);
            setPolicyValues(undefined);
            applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />

      <ConfirmationModal
        title={'Are you sure you want to initiate the insurance run for this policy?'}
        isOpen={isInitiateInsuranceRunModalOpen}
        onClose={() => {
          setIsInitiateInsuranceRunModalOpen(false);
          setPolicyValues(undefined);
        }}
        onConfirm={() => {
          if (policyValues) {
            initiateInsuranceRun(policyValues);
            setIsInitiateInsuranceRunModalOpen(false);
            setPolicyValues(undefined);
            applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
      <Modal
        title={policyVehicles?.policy_number}
        open={policyVehiclesRangeModalOpen}
        showClose
        onClose={() => {
          setPolicyVehiclesRangeModalOpen(false);
          applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          setPolicyVehicles(undefined);
        }}
        styled={{ width: '95vw', minWidth: '600px' }}
      >
        <IncludeExcludeVehiclesForm
          vehicles={policyVehicles?.vehiclesListByVrms || []}
          policyId={policyVehicles?.policy_id || ''}
          onClose={() => {
            setPolicyVehiclesRangeModalOpen(false);
            setPolicyVehicles(undefined);
          }}
        />
      </Modal>
    </>
  );
};
