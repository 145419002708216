import React, { useCallback, useState } from 'react';
import { Control, FieldError, FieldValues, useForm } from 'react-hook-form';
import { FlexContainer } from '../../../core/pageTitle/pageTitle.styles';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { StyledSecondaryButton, StyledTextField, StyledUploaderInput } from './viewReviewPolicyForm.styles';
import {
  InsurancePolicy,
  InsurancePolicyDocuments,
  ModifiedInsurancePolicy,
  RenewPolicy,
} from '../../../models/insurancePolicy';
import { DateTimePickerComponent } from '../../../uiComponents/customComponents/dateTimePicker/dateTimePicker';
import { InputChips } from '../../../uiComponents/inputChips/inputChips';
import { updateInsurancePolicy } from '../../../api/patch/insurance.patch';
import { fileToBase64 } from '../../../utils/utils';
import { Notification } from '../../../uiComponents/toast/toast';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { StyledFieldContainer } from '../createEditPolicyForm/createEditPolicyForm.styles';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import { getInsurancePolicyDocuments } from '../../../api/get/insurance.get';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { OptionList } from '../../../utils/props';

interface ViewReviewPolicyFormProps {
  isInViewMode: boolean;
  renewPolicy: () => void;
  values?: ModifiedInsurancePolicy | null;
  insuranceCompanyOptions: OptionList[];
  onFormSubmit: () => void;
  onClose: () => void;
}

export const ViewReviewPolicyForm = ({
  isInViewMode,
  values,
  onClose,
  onFormSubmit,
  renewPolicy,
  insuranceCompanyOptions,
}: ViewReviewPolicyFormProps) => {
  const getDefaultHour = (date: string | undefined) => {
    if (date) {
      const d = new Date(date);
      return d.getHours();
    } else {
      return 0;
    }
  };

  const getDefaultMinutes = (date: string | undefined) => {
    if (date) {
      const d = new Date(date);
      return d.getMinutes();
    } else {
      return 0;
    }
  };

  const [startDateHours] = useState<number>(getDefaultHour(values?.start_date));
  const [startDateMinutes] = useState<number>(getDefaultMinutes(values?.start_date));
  const [endDateHours] = useState<number>(getDefaultHour(values?.end_date));
  const [endDateMinutes] = useState<number>(getDefaultMinutes(values?.end_date));
  const getVrmList = () => {
    if (!values?.vrm_number_plates || !values!.vrm_number_plates[0]) return [];
    return values?.vrm_number_plates.map((item) => {
      return { value: item };
    });
  };
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<InsurancePolicy>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues:
      isInViewMode && values
        ? { ...values, vrm_number_plates: getVrmList() }
        : {
            insurance_company: values?.insurance_company,
            policy_number: values?.policy_number,
            start_date: '',
            end_date: '',
            vrm_number_plates: getVrmList(),
          },
  });

  const onSubmit = async (values: InsurancePolicy) => {
    setIsFormSubmitting(true);
    const { id, start_date, end_date, policy_certificate } = values;
    const payload: RenewPolicy = {
      id,
      body: {
        start_date,
        end_date,
        policyCertificateUrl: policy_certificate
          ? {
              content: await fileToBase64(policy_certificate),
              fileName: 'insurance-policy-certificate',
              type: policy_certificate.type,
            }
          : undefined,
      },
    };

    updateInsurancePolicy(payload)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Policy has been successfully renewed',
          isAlert: true,
        });
        onFormSubmit();
      })
      .catch(() =>
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error renewing policy',
          isAlert: true,
        })
      )
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  const fetchInsurancePolicyDocs = useCallback(() => {
    if (values?.id) {
      getInsurancePolicyDocuments(values?.id).then((response: { data: InsurancePolicyDocuments[] }) => {
        if (response.data) {
          response.data.forEach((doc) => {
            window.open(doc.url, '_blank');
          });
        }
      });
    }
  }, [values?.id]);

  const getDefaultInsuranceCompany = () => {
    const result = insuranceCompanyOptions.find((company) => company.label === values?.insurance_company);
    return result?.value;
  };

  const hoursOptions = () => {
    const result: Array<OptionList> = [];
    for (let i = 0; i < 24; i++) {
      result.push({
        value: i.toString(),
        label: i > 9 ? i.toString() : `0${i}`,
      } as OptionList);
    }
    return result;
  };

  const minutesOptions = () => {
    const result: Array<OptionList> = [];
    for (let i = 0; i < 60; i++) {
      result.push({
        value: i.toString(),
        label: i > 9 ? i.toString() : `0${i}`,
      } as OptionList);
    }
    return result;
  };

  return (
    <>
      <GridLayout template={2} gap="32px 24px">
        <StyledFieldContainer vertical>
          <TextFieldLabel $isRequired>Insurance company</TextFieldLabel>
          <DropDown
            name="insurance_company"
            required={{
              required: 'Insurance company is a required field',
            }}
            value={getDefaultInsuranceCompany()}
            error={errors.insurance_company}
            placeholder="Enter company name"
            options={insuranceCompanyOptions}
            control={control as unknown as Control<FieldValues>}
            useLabel
            disabled
          />
        </StyledFieldContainer>
        <StyledTextField
          {...register('policy_number', {
            required: 'Policy number is a required field',
          })}
          label="Policy number"
          error={errors.policy_number}
          placeholder="Enter policy number"
          type="text"
          required
          disabled
        />

        <DateTimePickerComponent
          control={control}
          name="start_date"
          label="Start date"
          errors={errors.start_date}
          format="y/MM/dd"
          disabled
        />
        <div>
          <TextFieldLabel>Time</TextFieldLabel>
          <FlexLayout itemsX="space-between" gap="10px" styled={{ marginTop: '-8px' }}>
            <DropDown
              styled={{ width: '230px', marginTop: '8px' }}
              name=""
              options={hoursOptions()}
              placeholder="--"
              disabled
              defaultValue={startDateHours.toString()}
              control={control as unknown as Control<FieldValues>}
            />
            <DropDown
              styled={{ width: '230px', marginTop: '8px' }}
              name=""
              options={minutesOptions()}
              placeholder="--"
              disabled
              defaultValue={startDateMinutes.toString()}
              control={control as unknown as Control<FieldValues>}
            />
          </FlexLayout>
        </div>

        <DateTimePickerComponent
          control={control}
          name="end_date"
          label="End date"
          errors={errors.start_date}
          format="y/MM/dd"
          disabled
        />
        <div>
          <TextFieldLabel>Time</TextFieldLabel>
          <FlexLayout itemsX="space-between" gap="10px" styled={{ marginTop: '-8px' }}>
            <DropDown
              styled={{ width: '230px', marginTop: '8px' }}
              name=""
              options={hoursOptions()}
              placeholder="--"
              disabled
              defaultValue={endDateHours.toString()}
              control={control as unknown as Control<FieldValues>}
            />
            <DropDown
              styled={{ width: '230px', marginTop: '8px' }}
              name=""
              options={minutesOptions()}
              placeholder="--"
              disabled
              defaultValue={endDateMinutes.toString()}
              control={control as unknown as Control<FieldValues>}
            />
          </FlexLayout>
        </div>
        <StyledUploaderInput
          control={control as unknown as Control<FieldValues>}
          name="policy_certificate"
          error={errors?.policy_certificate}
          label="Upload certificate"
          disabled
        />
        <InputChips
          name="vrm_number_plates"
          label=""
          header="Number plate range"
          register={register}
          control={control}
          required={{
            required: 'Number plate range are a required field',
          }}
          error={errors.vrm_number_plates as FieldError}
          disabled
        />
      </GridLayout>

      <FlexContainer style={{ marginTop: 20 }} itemsX="end">
        <StyledSecondaryButton onClick={() => onClose()}>Cancel</StyledSecondaryButton>
        <StyledSecondaryButton onClick={() => fetchInsurancePolicyDocs()}>Generate samples</StyledSecondaryButton>
        <PrimaryButton
          isProcessing={isFormSubmitting}
          onClick={isInViewMode ? () => renewPolicy() : handleSubmit(onSubmit)}
        >
          {isInViewMode ? 'Renew' : 'Save'}
        </PrimaryButton>
      </FlexContainer>
    </>
  );
};
