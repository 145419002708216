import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsEye } from 'react-icons/bs';
import { FiMinusCircle } from 'react-icons/fi';
import moment from 'moment';
import { CancellationForm } from './cancellationForm/cancellationForm';
import { AGREEMENTS } from '../../../consts/routes';
import { AGREEMENT_TERMINATED } from '../../../consts/agreement';
import { Aftercare } from '../../../models/aftercare';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { aftercareListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import {
  aftercareServiceOptions,
  aftercareStatusListOptions,
} from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { Tag } from '../../../uiComponents/customComponents/tag/tag';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30 } from '../../../common/styles/Colors';
import { statusColors } from '../../../common/utils';
import { APP_CONTEXT } from '../../../utils/context';
import { OptionList } from '../../../utils/props';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { useGetAndMergeAftercareListQuery } from '../../../api/listAndMerge/listAndMergeAftercareApiSlice';

export const AftercareList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [agreementToCancel, setAgreementToCancel] = useState<Aftercare>();
  const [statusFilter, setStatusFilter] = useState<OptionList[]>([]);
  const [selectedPlanType, setSelectedPlanType] = useState<OptionList[]>([]);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const navigate = useNavigate();

  const {
    setTableData,
    setSortingColumn,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    getSortDirection,
    setSortAscending,
    filterQuery,
    setTableFilters,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const aftercareColumns = useMemo(() => [...aftercareListColumns], []);

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=agreement.updated_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: aftercareList,
    isLoading: isAftercareListLoading,
    isFetching: isAftercareListFetching,
    refetch,
  } = useGetAndMergeAftercareListQuery({ query: queryStringState, refetch: refetchData });

  const filters: FilterItem[] = [
    {
      name: 'plan_type',
      element: (
        <DropDownFilter
          name="plan_type"
          placeholder="Plan type"
          options={aftercareServiceOptions}
          multiValues={selectedPlanType}
          title="Plan type"
          onChange={(items) => setSelectedPlanType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'agreement_status',
      element: (
        <DropDownFilter
          name="agreement_status"
          placeholder="Status"
          options={aftercareStatusListOptions}
          multiValues={statusFilter}
          title="Status"
          onChange={(items) => setStatusFilter(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Agreement completion"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const handleGetAftercareResponse = useCallback(
    (count: number, data: Aftercare[]) => {
      const aftercareRows = data?.map((aftercareItem: Aftercare) => {
        return {
          rowData: { data: aftercareItem },
          cells: [
            <TableTextCell value={aftercareItem?.driver_name} />,
            <TableTextCell value={aftercareItem?.vehicle_name} />,
            <TableTextCell value={aftercareItem?.vrm} />,
            <TableTextCell
              value={aftercareItem?.end_date ? moment(aftercareItem?.end_date).format('DD MMM YYYY') : '-'}
            />,
            <FlexLayout itemsX="start">
              <Tag key={`aftercare_agreement_status_${aftercareItem?.agreement_type}`} color={PRIMARY_PURPLE}>
                {aftercareItem?.agreement_type}
              </Tag>
            </FlexLayout>,
            <FlexLayout itemsX="start">
              <Tag
                key={`aftercare_agreement_status_${aftercareItem?.id}`}
                color={statusColors[aftercareItem.agreement_status] ?? PRIMARY_PURPLE}
              >
                {aftercareItem.agreement_status}
              </Tag>
            </FlexLayout>,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => navigate(`${AGREEMENTS}/${aftercareItem?.id}`)}
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View agreement page"
              />

              <ActionIcon
                disabled={aftercareItem.agreement_status === AGREEMENT_TERMINATED}
                onClick={() => {
                  setAgreementToCancel(aftercareItem);
                  setIsCancelModalOpen(true);
                }}
                icon={
                  <FiMinusCircle
                    size={24}
                    color={
                      aftercareItem.agreement_status === AGREEMENT_TERMINATED ? SECONDARY_PURPLE_30 : PRIMARY_PURPLE
                    }
                  />
                }
                tooltip="Terminate aftercare"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(aftercareRows);
      setTotalRows(count);
    },
    [setTableData, setTotalRows, navigate]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('aftercareListPage');
    setPageTitle('Aftercare');
    setSortingColumn('agreement.updated_date');
    setSortAscending(false);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'agreement_status', options: statusFilter },
      { columnName: 'agreement_type', options: selectedPlanType },
      {
        columnName: 'agreement.end_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, statusFilter, selectedPlanType]);

  useEffect(() => {
    if (aftercareList) {
      handleGetAftercareResponse(aftercareList.count, aftercareList.data);
    }
  }, [aftercareList, queryStringState, handleGetAftercareResponse]);

  const onClearClick = useCallback(() => {
    setStatusFilter([]);
    setSelectedPlanType([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        header="Aftercare list"
        isLoading={isAftercareListLoading || isAftercareListFetching}
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        disableApply={invalidDates}
        sortAscending={sortAscending}
        columns={aftercareColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onRowClick={({ data }: { data: Aftercare }) => {
          navigate(`${AGREEMENTS}/${data?.id}`);
        }}
      />
      <Modal open={isCancelModalOpen} showClose={true} onClose={() => setIsCancelModalOpen(false)}>
        <CancellationForm
          aftercare={agreementToCancel}
          onFormSubmit={() => {
            setIsCancelModalOpen(false);
            refetchToFirstPage();
          }}
        />
      </Modal>
    </>
  );
};
