import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppWrapper } from './core/appWrapper/appWrapper';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { store } from './store';
import { Provider } from 'react-redux';
import { ApiProvider } from '@reduxjs/toolkit/query/react';
import { apiSlice } from './api/baseApi';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_FEATURE_FLAG_CLIENT_SIDE_ID ?? '66053881c205560f31f0f6f9',
    context: {
      kind: 'pulse',
      key: 'global',
      name: 'Global',
    },
  });
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <ApiProvider api={apiSlice}>
        <Provider store={store}>
          <LDProvider>
            <AppWrapper />
          </LDProvider>
        </Provider>
      </ApiProvider>
    </React.StrictMode>
  );
})();
