import { styled } from 'styled-components';

export const RouteContainer = styled.div``;

export const Div = styled.div`
  position: absolute;
  top: 16px;
`;

export const ChildRouteContainer = styled.div`
  margin: 0 32px;
`;

export const Ref = styled.div`
  position: absolute;
  margin-top: -150px;
`;
