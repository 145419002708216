import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { ukPostCodeFormat } from './validations';
import { PulseUser } from '../models/employee';
import { Notification } from '../uiComponents/toast/toast';

/**
 * Get data in your storage
 * @category Utils
 * @param {string} key - What the name of the local storage item will be
 * @return {object} - Returns a the required values
 */
export function getFromStorage(key: string) {
  if (!key) {
    return null;
  }
  try {
    const valueStr = localStorage.getItem(key);
    if (valueStr) {
      return valueStr;
    }
    return null;
  } catch (err) {
    return null;
  }
}

/**
 * Get Logged In Users data
 * @category Utils
 * @return {object} Returns a user object if token has not expired
 */
export function getCurrentUser(): PulseUser | null {
  const token = localStorage.getItem('jwt');
  if (token) {
    const decodeJwt = jwtDecode(token) as PulseUser;
    if (decodeJwt && decodeJwt.exp && new Date().getTime() < decodeJwt.exp * 1000) {
      return decodeJwt;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

/**
 * Set data in your storage
 * @category Utils
 * @param {string} key - What the name of the local storage item will be
 * @param {object} obj - The value of the storage item
 * @return {boolean} Returns a success state
 */
export function setInStorage(key: string, obj: object) {
  if (!key) {
    return false;
  }
  try {
    localStorage.setItem(key, JSON.stringify(obj));
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Check if an ID number is valid
 * @category Utils
 * @param {Number} idNumber
 * @returns {boolean}
 */
export function isValidIDNumber(idNumber: string) {
  const idNum = idNumber.toString().replace(' ', '');
  const r = /^\d{10}[0-1]\d{2}$/;
  if (idNum === '0000000000000') return false;
  if (!r.test(idNum)) return false;
  const n: string = idNum;
  const p1: number =
    parseInt(n[0], 10) +
    parseInt(n[2], 10) +
    parseInt(n[4], 10) +
    parseInt(n[6], 10) +
    parseInt(n[8], 10) +
    parseInt(n[10], 10);
  const p2: string = (parseInt(n[1] + n[3] + n[5] + n[7] + n[9] + n[11], 10) * 2).toString();
  let p3 = 0;
  for (let i = 0; i < p2.length; i += 1) {
    p3 += parseInt(p2[i], 10);
  }
  const check = 10 - +(p1 + p3).toString()[(p1 + p3).toString().length - 1];
  const checkChar = check > 9 ? check.toString()[1] : check.toString();
  if (checkChar !== idNum[12]) return false;
  return true;
}

/**
 * Convert file to base64 format/string
 * @category Utils
 * @param {File} file
 * @returns {string}
 */
export const fileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (() => {
      return (e: ProgressEvent<FileReader>) => {
        const binaryData = e?.target?.result as string;
        // Converting Binary Data to base 64
        const base64String = window.btoa(binaryData);
        resolve(base64String);
      };
    })();
    reader.onerror = (error) => {
      reject(error);
    };
    // Read in the image file as a data URL
    reader.readAsBinaryString(file);
  });

export const formatAvailableDate = ({ date }: { date: string }) => {
  return <span>{date ? moment(date).format('YY/MM/DD, HH:mm') : '-'}</span>;
};

export const checkPostCodeFormat = (postCode: string) => {
  const postCodeChecked = postCode.toUpperCase().replace(/[^0-9a-z]/gi, '');
  const splitPostCode = postCodeChecked.match(ukPostCodeFormat);
  if (splitPostCode) {
    splitPostCode.shift();
    return splitPostCode.join(' ');
  }

  return postCode;
};

export function useControlledState<T>(
  value: T | undefined,
  defaultValue: T,
  onValueChanged?: (v: T) => void
): [T, (v: T) => void] {
  const [managedValue, setManagedValue] = useState(defaultValue);

  const setValue = useCallback(
    (v: T) => {
      if (value == null) {
        setManagedValue(v);
      }
      onValueChanged?.(v);
    },
    [value, onValueChanged]
  );

  return [value ?? managedValue, setValue];
}

export const notNull = (val: any) => val !== null && val !== undefined && val !== 'null' && val !== 'undefined';

export const downloadReport = (prcUrl: string) => {
  window.open(prcUrl);
};

export const alterString = (str: string) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export type StarType = 'full' | 'half' | 'empty';

export const getStars = (wholeStarRating: number, decimalRating: number) => {
  const ratings: StarType[] = [];
  for (let i = 0; i < wholeStarRating; i++) {
    ratings.push('full');
  }
  if (decimalRating > 0.25 && !ratings.includes('half')) {
    ratings.push('half');
  }
  const empties: number = 5 - ratings?.length;
  for (let j = 0; j < empties; j++) {
    ratings.push('empty');
  }
  return ratings;
};

export const isDateWithinOneMonthBefore = (date: string) => {
  const today: Moment = moment();
  const oneMonthBeforeStartDate: Moment = moment(date).subtract(1, 'month');

  return today.isSameOrAfter(oneMonthBeforeStartDate) && today.isBefore(moment(date));
};

export const isDateWithinOneWeekBefore = (date: string) => {
  const today = new Date().getTime();
  const oneWeekAfter = new Date().getTime() + 604800000;
  return moment(date).isBetween(today, oneWeekAfter);
};

export const isDateWithinOneDayBefore = (date: string) => {
  const today: Moment = moment();
  const oneDayBeforeStartDate: Moment = moment(date).subtract(1, 'day');

  return today.isSameOrAfter(oneDayBeforeStartDate) && today.isBefore(moment(date));
};

type NotificationType = 'success' | 'error';

export const renderNotification = (
  type: NotificationType,
  title: string,
  message: string,
  isAlert: boolean = true
): void => {
  Notification({
    type,
    title,
    message,
    isAlert,
  });
};

export const getBlob = async (imgUrl: string) => {
  const img = await fetch(imgUrl);
  const blob = await img.blob();
  return blob;
};
