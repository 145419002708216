import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PageContainer, Sections } from './driverPage.styles';
import { DriverButtonGroup } from '../driverButtons/driverButtonGroup';
import { DriverDetails } from '../driverDetails/driverDetails';
import { DriverHolidays } from '../driverHolidays/driverHolidays';
import { DriverInfoCard } from '../driverInfoCard/driverInfoCard';
import { DriverOrdwayDetails } from '../driverOrdwayDetails/driverOrdwayDetails';
import { DriverAgreements } from '../driverTables/driverAgreements';
import { DriverApplications } from '../driverTables/driverApplications';
import { DriverFreshdeskTickets } from '../driverTables/driverFreshdeskTickets';
import { DriverMileages } from '../driverTables/driverMileages';
import { DriverVehicles } from '../driverTables/driverVehicles';
import { DriverTags } from '../driverTags/driverTags';
import { DriverTelematics } from '../driverTelematics/driverTelematics';
import { FINANCE } from '../../../../consts/department';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { Notes } from '../../../../uiComponents/notes/notes';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { DocumentsTable } from '../../../../uiComponents/table/documentTable/documentTable';
import { getDriver, getDriverTelematics } from '../../../../api/get/driver.get';
import { Driver } from '../../../../models/driver';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../../utils/context';
import { AgreementAudits } from '../../../agreements/agreement/auditInformation';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface DrivereInfoProps {
  driver: Driver;
}

export interface TelematicsData {
  shouldShowTelematicsInfo: boolean;
  engineState: string;
  driverScore: number;
  driverScoreDifference: number;
  driverSkillLevel: string;
  driverLocation: string;
  odometer: string;
  yearlyMileage: number;
  weeklyMileage: {
    thisWeeksMileage: number;
    lastWeeksMileage: number;
  };
}

export const DriverPage = () => {
  const employeeDepartment: string | null = localStorage.getItem('department');
  const { superAdmin } = useContext(APP_CONTEXT);
  const { driverId } = useParams();
  const { biAnnualAudit } = useFlags();
  const [driver, setDriver] = useState<Driver>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isFreshdeskOpen, setIsFreshdeskOpen] = useState<boolean>(false);
  const [isMileageModalOpen, setIsMileageModalOpen] = useState<boolean>(false);
  const [isInArrears, setIsInArrears] = useState<boolean>(false);
  const [showTelematics, setShowTelematics] = useState<boolean>(false);
  const [telematicsData, setTelematicsData] = useState<TelematicsData>();
  const [telematicsLoading, setTelematicsLoading] = useState<boolean>(true);
  const [isForAudit, setIsForAudit] = useState<boolean>(false);

  const fetchDriverData = useCallback(() => {
    setLoading(true);
    if (driverId) {
      getDriver(driverId).then(({ data }) => {
        setDriver(data);
        if (AgreementAudits?.find((a) => a?.driver_id === driverId) != null) {
          setIsForAudit(true);
        }
      });
    }
    setLoading(false);
  }, [driverId]);

  const fetchTelematicsData = useCallback((pulseDriverId: string, vehicleId: string) => {
    getDriverTelematics({
      pulseDriverId,
      vehicleId,
    }).then(({ data }) => {
      if (data) {
        setTelematicsLoading(false);
        setShowTelematics(data.shouldShowTelematicsInfo);
        setTelematicsData(data.results);
      }
    });
  }, []);

  useEffect(() => {
    fetchDriverData();
  }, [fetchDriverData]);

  useEffect(() => {
    if (driver?.id && driver?.vehicle_vin) {
      fetchTelematicsData(driver?.id, driver?.vehicle_vin);
    }
  }, [driver, fetchTelematicsData]);

  useEffect(() => {
    setDriver(undefined);
  }, [driverId]);

  return (
    <>
      <PageContainer>
        <DriverButtonGroup
          loading={loading}
          setIsMileageModalOpen={setIsMileageModalOpen}
          setIsFreshdeskOpen={setIsFreshdeskOpen}
        />
        {driver ? (
          <FlexLayout gap={16}>
            <FlexLayout vertical gap={32} styled={{ width: 440 }}>
              <DriverInfoCard driver={driver} inArrears={isInArrears} />
              {driver?.tag_type && <DriverTags tags={driver.tag_type} />}
              {driver?.id && <Notes categoryId={driver?.id} />}
            </FlexLayout>
            <Sections>
              <DriverDetails
                driver={driver}
                tableHeader="Basic details"
                fetchDrivereData={() => fetchDriverData()}
                styled={{ marginBottom: 16 }}
              />
              {(!isForAudit || !biAnnualAudit) && (
                <DriverOrdwayDetails
                  driver={driver}
                  inArrears={isInArrears}
                  checkForArrears={(inArrears) => setIsInArrears(inArrears)}
                />
              )}
              {driver?.id && (
                <>
                  <DriverVehicles driverId={driver.id} tableHeader="Current vehicles" />
                  <DriverAgreements driverId={driver.id} tableHeader="Agreements" />
                  <DriverApplications driverId={driver.id} tableHeader="Applications" />
                  <DocumentsTable
                    categoryId={driver.id}
                    documentCategory="DRIVER"
                    tableHeader="Documents"
                    embedded={true}
                  />
                </>
              )}
              {(employeeDepartment === FINANCE || superAdmin) &&
                driver?.id &&
                driver?.agreement_status &&
                driver?.agreement_type &&
                driver?.ordway_customer_id &&
                driver?.ordway_subscription_id &&
                driver?.driver_name &&
                driver?.agreement_id && (
                  <DriverHolidays
                    driverId={driver?.id}
                    tableHeader="Payment holidays"
                    agreementStatus={driver?.agreement_status}
                    agreementType={driver?.agreement_type}
                    ordwayCustomerId={driver?.ordway_customer_id}
                    ordwaySubscriptionId={driver?.ordway_subscription_id}
                    driverName={driver?.driver_name}
                    agreementId={driver?.agreement_id}
                  />
                )}
              <DriverTelematics
                telematicsData={telematicsData}
                showTelematics={showTelematics}
                agrStatus={driver?.agreement_status}
                vin={driver?.vehicle_vin ?? ''}
                loading={telematicsLoading}
              />
            </Sections>
          </FlexLayout>
        ) : (
          <FlexLayout itemsX="center" styled={{ marginTop: 16 }}>
            <Spinner size={32} color={PRIMARY_PURPLE} />
          </FlexLayout>
        )}
      </PageContainer>
      {driver?.id && (
        <Modal
          styled={{ width: '80vw', minWidth: 400 }}
          title="Driver mileage"
          open={isMileageModalOpen}
          showClose
          onClose={() => setIsMileageModalOpen(false)}
        >
          <DriverMileages driverId={driver?.id} />
        </Modal>
      )}
      {driver?.email && (
        <Modal
          styled={{ width: '80vw', minWidth: 600 }}
          title="Freshdesk tickets"
          open={isFreshdeskOpen}
          showClose
          onClose={() => setIsFreshdeskOpen(false)}
        >
          <DriverFreshdeskTickets email={driver?.email} />
        </Modal>
      )}
    </>
  );
};
