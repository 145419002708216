import React, { useState } from 'react';
import { ImgContainer, Image, ActionContainer } from './documentConfirmation.styles';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../buttons/secondaryButton/secondaryButton';
import { Text } from '../../text/text';

interface DocumentConfirmationProps {
  source: string | undefined;
  name: string;
  switchToUpload: () => void;
}

export const DocumentConfirmation = ({ source, name, switchToUpload }: DocumentConfirmationProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  return (
    <ImgContainer vertical>
      <Image alt="document" src={source} />
      <Text styled={{ margin: '10px 0 20px 20px' }} variant="body6" weight={800}>
        {name}
      </Text>
      <ActionContainer itemsX="center" vertical>
        <PrimaryButton styled={{ width: '90%' }} onClick={() => setIsButtonDisabled(true)} disabled={isButtonDisabled}>
          The document is checked
        </PrimaryButton>
        <SecondaryButton styled={{ width: '90%', marginTop: 10 }} onClick={switchToUpload}>
          Upload new document
        </SecondaryButton>
      </ActionContainer>
    </ImgContainer>
  );
};
