import React, { useState } from 'react';
import { BsClipboard } from 'react-icons/bs';
import { FiEye } from 'react-icons/fi';
import { CollapsiblePanel } from '../../../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Text } from '../../../../../../uiComponents/text/text';
import { PRIMARY_PURPLE, PRIMARY_WHITE, SECONDARY_PURPLE_30 } from '../../../../../../common/styles/Colors';
import { Modal } from '../../../../../../uiComponents/modals/modal';
import { DamageReportForm } from '../damageReportForm/damageReportForm';
import { DamageReportItem, DamageResolutionItem } from '../../../../../../consts/conditionReport';
import {
  BackgroundImage,
  FakeButtonSection,
  ImageContainer,
  ImageContainerSection,
  TextBox,
  TextLabelPurple,
} from './damageLogReport.styles';
import { TextFieldLabel } from '../../../../../../uiComponents/inputs/textField/textField.styles';
import { damageTypeOptions } from '../../../../../../consts/vehicle';
import { PrimaryButton } from '../../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { DamageResolutionForm } from '../damageResolutionForm/damageResolutionForm';
import { Tag } from '../../../../../../uiComponents/customComponents/tag/tag';
import { ImageArrayType } from '../../../../../../models/transfer';

interface DamageLogReportInfo {
  values: DamageReportItem;
  onSubmit: (submitValues: DamageReportItem) => Promise<any>;
  onResolutionSubmit: (submitValues: DamageResolutionItem) => Promise<void>;
  resolved?: boolean;
}

export const DamageLogReport = ({ values, onSubmit, onResolutionSubmit, resolved }: DamageLogReportInfo) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [openDamageLogModal, setOpenDamageLogModal] = useState<boolean>(false);
  const [openDamageImageModal, setOpenDamageImageModal] = useState<boolean>(false);
  const [openDamageResolutionFormModal, setOpenDamageResolutionFormModal] = useState<boolean>(false);
  const [viewImage, setViewImage] = useState<string>('');
  const [submittingDamage, setSubmittingDamage] = useState<boolean>(false);

  const onFormSubmit = async (submitValues: DamageReportItem) => {
    setSubmittingDamage(true);
    await onSubmit(submitValues);
    setSubmittingDamage(false);
    setOpenDamageLogModal(false);
  };

  const onFormResolutionSubmit = async (submitValues: DamageResolutionItem) => {
    setSubmittingDamage(true);
    await onResolutionSubmit(submitValues);
    setOpenDamageResolutionFormModal(false);
    setSubmittingDamage(false);
  };

  function getTypeOptionValue(val: string) {
    return damageTypeOptions.find((item) => item.value === val)?.label;
  }

  function view(photo: File | Blob | ImageArrayType) {
    setViewImage('s3_url' in photo ? photo.s3_url : URL.createObjectURL(photo as File));
    setOpenDamageImageModal(true);
  }

  return (
    <>
      <CollapsiblePanel
        header={
          <FlexLayout gap={16} itemsY="center" itemsX="space-between" styled={{ width: '100%' }}>
            <FlexLayout gap={16} itemsY="center">
              <BsClipboard size={32} color={PRIMARY_PURPLE} />
              <div>
                <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                  {`${values.damaged_area} - ${values.damage_description}`}
                </Text>
              </div>
            </FlexLayout>
            {resolved && <Tag color={SECONDARY_PURPLE_30}>Resolved</Tag>}
          </FlexLayout>
        }
        expanded={!isCollapsed}
        onCollapse={() => setIsCollapsed(!isCollapsed)}
        styled={{ marginTop: 16 }}
      >
        <div>
          <FlexLayout gap={16} itemsY="center">
            <BsClipboard size={32} color={PRIMARY_PURPLE} />
            <div>
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                {`${values.damaged_area} - ${values.damage_description}`}
              </Text>
            </div>
          </FlexLayout>
          <GridLayout template={2} gap={27} styled={{ marginTop: '25px' }}>
            <div>
              <TextFieldLabel>Damaged type</TextFieldLabel>
              <TextBox>{getTypeOptionValue(values?.damaged_type)}</TextBox>
            </div>
            <div />
            <div>
              <TextFieldLabel>Damaged area name</TextFieldLabel>
              <TextBox>{values?.damaged_area}</TextBox>
            </div>
            <div>
              <TextFieldLabel>Damage description</TextFieldLabel>
              <TextBox>{values?.damage_description}</TextBox>
            </div>
            <div>
              <TextFieldLabel>Add photos</TextFieldLabel>
              {values.files?.map((photo, index) => {
                return (
                  <ImageContainerSection key={index}>
                    <ImageContainer>
                      <BackgroundImage
                        draggable="false"
                        alt="image"
                        src={'s3_url' in photo ? photo.s3_url : URL.createObjectURL(photo as File)}
                      />
                    </ImageContainer>
                    <TextLabelPurple>{(photo as File)?.name}</TextLabelPurple>
                    <FakeButtonSection onClick={() => view(photo)}>
                      <FiEye size={26} color={PRIMARY_WHITE} />
                    </FakeButtonSection>
                  </ImageContainerSection>
                );
              })}
            </div>
            <div>
              <TextFieldLabel>Damage detail</TextFieldLabel>
              <TextBox $textArea>{values?.damage_detail}</TextBox>
            </div>
            <div />
            {!resolved && (
              <FlexLayout gap={16} itemsX="end">
                <PrimaryButton onClick={() => setOpenDamageResolutionFormModal(true)}>Resolve damage</PrimaryButton>
                <PrimaryButton onClick={() => setOpenDamageLogModal(true)}>Edit</PrimaryButton>
              </FlexLayout>
            )}
          </GridLayout>
        </div>
      </CollapsiblePanel>
      <Modal
        title="Damage report"
        open={openDamageImageModal}
        onClose={() => {
          setOpenDamageImageModal(false);
        }}
        showClose={true}
        styled={{ minWidth: 600, width: '60vw' }}
      >
        <BackgroundImage $view draggable="false" alt="image" src={viewImage} />
      </Modal>
      <Modal
        title="Damage report"
        open={openDamageLogModal}
        onClose={() => {
          setOpenDamageLogModal(false);
        }}
        showClose={true}
        styled={{ minWidth: 600, width: '60vw' }}
      >
        <DamageReportForm
          values={values}
          submittingDamage={submittingDamage}
          onClose={() => {
            setOpenDamageLogModal(false);
          }}
          onSubmit={onFormSubmit}
        />
      </Modal>
      <Modal
        title="Damage resolution form"
        open={openDamageResolutionFormModal}
        onClose={() => {
          setOpenDamageResolutionFormModal(false);
        }}
        showClose={true}
        styled={{ minWidth: 600 }}
      >
        <DamageResolutionForm
          onClose={() => {
            setOpenDamageResolutionFormModal(false);
          }}
          submittingImages={submittingDamage}
          onSubmit={onFormResolutionSubmit}
        />
      </Modal>
    </>
  );
};
